import React from 'react'

import '../bootstrap/css/bootstrap.css'

export default function UnexpectedResponseCodeView(props)
{
    return (
        <div className="container-fluid text-center">
        <div className="row content">
        <div className="col-lg-6 text-left">
        <div id="content-container" className="container p-none">
        <h2> An unexpected response has been received! </h2>
        <p>
            Please attempt to navigate to the home page. If you continue to experience problems,
            please notify the LimnTech team.
        </p>
        </div></div></div></div>
    );
}
