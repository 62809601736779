import React, { useState } from 'react'

import useSWR from 'swr'
import { Table, Button, Form } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

import '../../BigButton.css'

function CameraCreationForm(props)
{
    const [serialNumber, setSerialNumber] = useState('')
    const [mountSide, setMountSide] = useState('UNASSIGNED')
    const [mountPosition, setMountPosition] = useState('UNASSIGNED')
    const [cameraManufacturer, setCameraManufacturer] = useState('')
    const [cameraModel, setCameraModel] = useState('')
    const [productId, setProductId] = useState('')
    const [macAddress, setMacAddress] = useState('')
    const [firmwareVersion, setFirmwareVersion] = useState('')
    const [invoiceNo, setInvoiceNo] = useState('')
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [cameraVendor, setCameraVendor] = useState('');
    const [isBroken, setIsBroken] = useState(false)
    const [truckAssignment, setTruckAssignment] = useState('UNASSIGNED')
    const [additionalComments, setAdditionalComments] = useState('')

    /*
     * State update callbacks
     */
    const onSerialChange = (event) =>
    {
        setSerialNumber(event.target.value);
    };
    const onMountSideChange = (event) =>
    {
        setMountSide(event.target.value);
    }
    const onMountPositionChange = (event) =>
    {
        setMountPosition(event.target.value)
    }
    const onCameraManufacturerChange = (event) =>
    {
        setCameraManufacturer(event.target.value)
    }
    const onCameraModelChange = (event) =>
    {
        setCameraModel(event.target.value)
    }
    const onProductIdChange = (event) =>
    {
        setProductId(event.target.value)
    }
    const onMacAddressChange = (event) =>
    {
        setMacAddress(event.target.value)
    }
    const onFirmwareVersionChange = (event) =>
    {
        setFirmwareVersion(event.target.value)
    }
    const onInvoiceNoChange = (event) =>
    {
        setInvoiceNo(event.target.value)
    }
    const onCameraVendorChange = (event) =>
    {
        setCameraVendor(event.target.value);
    }
    const onIsBrokenChange = (event) =>
    {
        setIsBroken(event.target.value)
    }
    const onTruckAssignment = (event) =>
    {
        setTruckAssignment(event.target.value);
    };
    const onAdditionalComments = (event) =>
    {
        setAdditionalComments(event.target.value);
    };

    const truckOptionList = () =>
    {
        let unassigned_truck_list = []

        for(let i = 0; i < props.data.trucks.length; i++)
        {
            /*
             * Multiple cameras can go to a truck
             */
            unassigned_truck_list.push(props.data.trucks[i]);
        }

        unassigned_truck_list = unassigned_truck_list.sort((a,b) => {
            return a.limntech_name.localeCompare(b.limntech_name)
        })
        return unassigned_truck_list.map(
            (truck, index) =>
        {
            return (
                <option value={truck.id}>
                    {truck.limntech_name}
                </option>
            );
        });
    }

    /*
     * Camera creation callbacks
     */
    const onCreateNewCamera = (event) =>
    {
        if(!parseInt(serialNumber))
        {
            alert('Serial number needs to an integer');
            return;
        }
        for(let i = 0; i < props.data.cameras.length; i++)
        {
            if(props.data.cameras[i].serial_no === serialNumber)
            {
                alert('The serial number you entered is already assigned to a camera!');
                return;
            }
        }

        let post_obj = {
            serial_no: serialNumber,
            manufacturer: cameraManufacturer,
            model: cameraModel,
            product_id: productId,
            mac_address: macAddress,
            firmware_version: firmwareVersion,
            invoice_no: invoiceNo,
            invoice_date: invoiceDate,
            vendor: cameraVendor,
            is_broken: isBroken
        };

        if(truckAssignment !== 'UNASSIGNED')
        {
            post_obj['truck'] = truckAssignment;
        }
        if(mountSide !== 'UNASSIGNED')
        {
            post_obj['side'] = mountSide
        }
        if(mountPosition !== 'UNASSIGNED')
        {
            post_obj['location'] = mountPosition
        }

        props.onSubmit(post_obj, additionalComments)
    };

    return (
        <div>
            <h1> Create Camera </h1>
            <Form.Group>
                <Table hover>
                    <tbody>
                        <tr>
                            <td> Serial Number </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onSerialChange}
                                    value={serialNumber}
                                    placeholder={"enter camera's serial number"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Mounting Side </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onMountSideChange}
                                    value={mountSide}
                                    size={"lg"}>
                                    <option value={'UNASSIGNED'}> Unassigned </option>
                                    <option value={'left'}> Left </option>
                                    <option value={'right'}> Right </option>
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Mounting Position </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onMountPositionChange}
                                    value={mountPosition}
                                    size={"lg"}>
                                    <option value={'UNASSIGNED'}> Unassigned or N/A </option>
                                    <option value={'front'}> Front </option>
                                    <option value={'rear'}> Rear </option>
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Manufacturer </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onCameraManufacturerChange}
                                    value={cameraManufacturer}
                                    placeholder={"enter camera's manufacturer e.g. Basler"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Model </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onCameraModelChange}
                                    value={cameraModel}
                                    placeholder={"enter camera's model e.g. a2A1920-51gcBAS"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Product ID </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onProductIdChange}
                                    value={productId}
                                    placeholder={"enter camera's product id e.g. 107823 – 21"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> MAC Address </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onMacAddressChange}
                                    value={macAddress}
                                    placeholder={"enter camera's MAC Address e.g. 00:30:53:35:D0:40"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Firmware Version </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onFirmwareVersionChange}
                                    value={firmwareVersion}
                                    placeholder={"enter camera's firmware version e.g. p=vg3_imx392c_bas/s=r/v=2.0.3/i=5121.28/h=ba69dd2"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Invoice Number </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onInvoiceNoChange}
                                    value={invoiceNo}
                                    placeholder={"enter camera's invoice number"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Invoice Date </td>
                            <td> <DatePicker
                                wrapperClassName='date_picker'
                                className='form-control form-control-lg'
                                selected={invoiceDate}
                                onChange={(date) =>setInvoiceDate(date)}/> </td>
                        </tr>
                        <tr>
                            <td> Vendor </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onCameraVendorChange}
                                    value={cameraVendor}
                                    placeholder={"enter camera's vendor e.g. Basler"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Are there any known problems with this camera? </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onIsBrokenChange}
                                    value={isBroken}
                                    size={"lg"}>
                                    <option value={true}> Yes </option>
                                    <option value={false}> No </option>
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Truck Assignment </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onTruckAssignment}
                                    value={truckAssignment}
                                    size={"lg"}>
                                    <option value={'UNASSIGNED'}> Unassigned </option>
                                    { truckOptionList() }
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Additional Comments </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onAdditionalComments}
                                    value={additionalComments}
                                    placeholder={"This field is optional"}
                                    size={"lg"} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form.Group>
            <p>{"\n\n"}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onCreateNewCamera}>
                    { props.buttonText }
            </Button>
            <p>{"\n\n"}</p>
        </div>
    );
}

export default function CameraCreation(props)
{
    const [submittingForm, setSubmittingForm] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [creationSuccessful, setCreationSuccessful] = useState(false);
    const [redirectToInventory, setRedirectToInventory] = useState(false);

    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: cameras, error: camera_error } = useSWR(
        resourceHandler.list_cameras().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && trucks && cameras);
    let error = !(!ca_error && !trucks_error && !camera_error);

    const onFailure = () =>
    {
        setApiError(true);
    }
    const onRedirectInventory = () =>
    {
        setRedirectToInventory(true);
    }

    const onFormSubmitSuccess = (response) =>
    {
        setCreationSuccessful(true);
    }

    const onInitialNoteSuccess = (response, camera_id, additional_comment) =>
    {
        if(additional_comment.length === 0)
        {
            onFormSubmitSuccess(null);
        }
        else
        {
            let post_obj = {
                camera: camera_id,
                comment: additional_comment,
                user: company_admin.id
            }

            requestHandler.post(
                resourceHandler.create_camera_note(),
                post_obj,
                onFormSubmitSuccess,
                onFailure);
        }
    };
    const onCameraCreationSuccess = (response, additional_comments) =>
    {
        let camera_id = response.data.id;
        let post_obj = {
            camera: camera_id,
            comment: "Initial entry manually entered",
            user: company_admin.id
        };

        const onSuccess = (response) =>
        {
            onInitialNoteSuccess(response, camera_id, additional_comments);
        };
        requestHandler.post(
            resourceHandler.create_camera_note(),
            post_obj,
            onSuccess,
            onFailure);
    }
    const onFormSubmitted = (post_obj, additional_comments) =>
    {
        const onSuccess = (response) =>
        {
            onCameraCreationSuccess(response, additional_comments);
        };

        requestHandler.post(
            resourceHandler.create_camera(),
            post_obj,
            onSuccess,
            onFailure);

        setSubmittingForm(true);
    }

    if(error || apiError)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(redirectToInventory)
    {
        return ( <Navigate to="/inventory_management/"/>);
    }
    else if(creationSuccessful)
    {
        return (
            <div>
                <h1> Camera Creation Success </h1>
                <p> Press the button below to return to inventory </p>
                <p> You may also navigate to any other page using the banner above </p>
                <Button
                    variant="primary"
                    className="BigButton"
                    onClick={onRedirectInventory}>
                        Inventory Management
                </Button>
            </div>
        );
    }
    else if(submittingForm)
    {
        return <ResourceLoader />;
    }
    else if(loaded)
    {
        let data = {
            company_admin: company_admin,
            trucks: trucks,
            cameras: cameras
        };

        return (
            <CameraCreationForm
                data={data}
                onSubmit={onFormSubmitted}
                buttonText={"Create New Camera"}
            />
        );
    }
    else
    {
        return <ResourceLoader />;
    }
}
