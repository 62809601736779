import { Table, Form, Button }  from 'react-bootstrap'

export default function PaintGunForm(props)
{
    const changeGunName = idx =>(event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].GunName = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const changeGunId = idx =>(event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].GunId = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const changeBeadId = idx => (event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].BeadId = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const changeBeadOffset = idx => (event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].BeadOffset = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const changeGunColor = idx => (event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].GunColor = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const changeX = idx => (event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].X = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const changeY = idx => (event) =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns[idx].Y = event.target.value;
        props.setPaintGuns(paint_guns);
    };
    const addGun = () =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns.push({
            GunName: "",
            GunId: "",
            BeadId: "",
            BeadOffset: "",
            GunColor: "yellow",
            X: "",
            Y: ""
        })
        props.setPaintGuns(paint_guns);
    }
    const deleteGun = () =>
    {
        let paint_guns = [ ...props.paintGuns ];
        paint_guns.pop();
        props.setPaintGuns(paint_guns);
    }

    return (
        <div>
            <h3> { props.name } </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            Gun Name
                        </td>
                        <td>
                            Gun ID
                        </td>
                        <td>
                            Bead ID
                        </td>
                        <td>
                            Bead Offset
                        </td>
                        <td>
                            Gun Color
                        </td>
                        <td>
                            X Offset
                        </td>
                        <td>
                            Y Offset
                        </td>
                    </tr>
                    {
                        props.paintGuns.map(
                            (yellow_gun, idx) =>
                        {
                            return (
                                <tr>
                                <td>
                                    <Form.Control
                                    value={yellow_gun.GunName}
                                    onChange={changeGunName(idx)}
                                    placeholder="Enter Gun Name"
                                    size="md"/>
                                </td>
                                <td>
                                    <Form.Control
                                    value={yellow_gun.GunId}
                                    onChange={changeGunId(idx)}
                                    placeholder="Enter Gun Id"
                                    size="md"/>
                                </td>
                                <td>
                                    <Form.Control
                                    value={yellow_gun.BeadId}
                                    onChange={changeBeadId(idx)}
                                    placeholder="Optional"
                                    size="md"/>
                                </td>
                                <td>
                                    <Form.Control
                                    value={yellow_gun.BeadOffset}
                                    onChange={changeBeadOffset(idx)}
                                    placeholder="Optional"
                                    size="md"/>
                                </td>
                                <td>
                                    <Form.Control
                                        value={yellow_gun.GunColor}
                                        as="select"
                                        name="counter"
                                        onChange={changeGunColor(idx)}
                                        size="md">
                                        <option value={"yellow"}>
                                            Yellow
                                        </option>
                                        <option value={"white"}>
                                            White
                                        </option>
                                    </Form.Control>
                                </td>
                                <td>
                                    <Form.Control
                                    value={yellow_gun.X}
                                    onChange={changeX(idx)}
                                    placeholder="Inches"
                                    size="md"/>
                                </td>
                                <td>
                                    <Form.Control
                                    value={yellow_gun.Y}
                                    onChange={changeY(idx)}
                                    placeholder="Inches"
                                    size="md"/>
                                </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </Table>
            </Form.Group>
            <Button
                onClick={addGun}
                type="submit"
                variant="primary"
                size="lg">
                Add Gun
            </Button>
            { "    " }
            <Button
                onClick={deleteGun}
                type="submit"
                variant="primary"
                size="lg">
                Delete Gun
            </Button>
        </div>
    );

}


