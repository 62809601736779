import React, { useState } from 'react'

import useSWR from 'swr'
import { Table, Button, Form } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

import '../../BigButton.css'

function PowerBoardCreationForm(props)
{
    const [serialNumber, setSerialNumber] = useState('')
    const [hardwareRevisionName, setHardwareRevisionName] = useState('');
    const [firmwareVersion, setFirmwareVersion] = useState('');
    const [boardBroken, setBoardBroken] = useState(false)
    const [cabinetAssignment, setCabinetAssignment] = useState('UNASSIGNED')
    const [additionalComments, setAdditionalComments] = useState('')

    /*
     * State update callbacks
     */
    const onSerialChange = (event) =>
    {
        setSerialNumber(event.target.value);
    };
    const onHardwareRevisionChange = (event) =>
    {
        setHardwareRevisionName(event.target.value);
    };
    const onFirmwareVersionChange = (event) =>
    {
        setFirmwareVersion(event.target.value);
    };
    const onBoardBroken = (event) =>
    {
        setBoardBroken(event.target.value);
    };
    const onCabinetAssignment = (event) =>
    {
        setCabinetAssignment(event.target.value);
    };
    const onAdditionalComments = (event) =>
    {
        setAdditionalComments(event.target.value);
    };

    const cabinetOptionList = () =>
    {
        let unassigned_cabinet_list = []

        for(let i = 0; i < props.data.cabinets.length; i++)
        {
            let match = false;
            for(let j = 0; j < props.data.power_boards.length; j++)
            {
                if(props.data.power_boards[j].cabinet === props.data.cabinets[i].id)
                {
                    match = true;
                    break;
                }
            }

            if(!match)
            {
                unassigned_cabinet_list.push(props.data.cabinets[i]);
            }
        }

        return unassigned_cabinet_list.map(
            (cabinet, index) =>
        {
            return (
                <option value={cabinet.id}>
                    {cabinet.serial_no}
                </option>
            );
        });
    }

    /*
     * Board creation callbacks
     */
    const onCreateNewPowerBoard = (event) =>
    {
        if(!parseInt(serialNumber))
        {
            alert('Serial number needs to be in the form of MMYYBN like 022312');
            return;
        }
        if(serialNumber.length !== 6)
        {
            alert('Serial number needs to be in the form of MMYYBN like 022312');
            return;
        }
        for(let i = 0; i < props.data.power_boards.length; i++)
        {
            if(props.data.power_boards[i].serial_no === serialNumber)
            {
                alert('The serial number you entered is already assigned to a power board!');
                return;
            }
        }
        if(hardwareRevisionName.length === 0)
        {
            alert('You must enter a hardware revision number!');
            return;
        }
        if(firmwareVersion.length === 0)
        {
            alert('You must enter a firmware version!')
            return;
        }

        let post_obj = {
            serial_no: serialNumber,
            hardware_revision: hardwareRevisionName,
            firmware_version: firmwareVersion,
            is_broken: boardBroken,
            is_deleted: false
        };

        if(cabinetAssignment !== 'UNASSIGNED')
        {
            post_obj['cabinet'] = cabinetAssignment;
        }

        props.onSubmit(post_obj, additionalComments)
    };

    return (
        <div>
            <h1> Create Power Board </h1>
            <Form.Group>
                <Table hover>
                    <tbody>
                        <tr>
                            <td> Serial Number </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onSerialChange}
                                    value={serialNumber}
                                    placeholder={"enter board's serial number"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Hardware Revision </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onHardwareRevisionChange}
                                    value={hardwareRevisionName}
                                    placeholder={"enter board's hardware revision (e.g LM_100_300_v1000)"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Firmware Version </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onFirmwareVersionChange}
                                    value={firmwareVersion}
                                    placeholder={"enter board's firmware version (e.g 1.4.1)"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Are there any known problems with the board? </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onBoardBroken}
                                    value={boardBroken}
                                    size={"lg"}>
                                    <option value={false}> No </option>
                                    <option value={true}> Yes </option>
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Cabinet Assignment </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onCabinetAssignment}
                                    value={cabinetAssignment}
                                    size={"lg"}>
                                    <option value={'UNASSIGNED'}> Unassigned </option>
                                    { cabinetOptionList() }
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Additional Comments </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onAdditionalComments}
                                    value={additionalComments}
                                    placeholder={"This field is optional"}
                                    size={"lg"} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form.Group>
            <p>{"\n\n"}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onCreateNewPowerBoard}>
                    { props.buttonText }
            </Button>
        </div>
    );
}

export default function PowerBoardCreation(props)
{
    const [submittingForm, setSubmittingForm] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [creationSuccessful, setCreationSuccessful] = useState(false);
    const [redirectToInventory, setRedirectToInventory] = useState(false);

    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: cabinets, error: cabinets_error } = useSWR(
        resourceHandler.list_cabinets('').url, requestHandler.cacheFetcher);
    const { data: power_boards, error: power_board_error } = useSWR(
        resourceHandler.list_power_boards().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && cabinets && power_boards);
    let error = !(!ca_error && !cabinets_error && !power_board_error);

    const onFailure = () =>
    {
        setApiError(true);
    }
    const onRedirectInventory = () =>
    {
        setRedirectToInventory(true);
    }

    const onFormSubmitSuccess = (response) =>
    {
        setCreationSuccessful(true);
    }
    const onInitialNoteSuccess = (response, power_board_id, additional_comment) =>
    {
        if(additional_comment.length === 0)
        {
            setCreationSuccessful(true);
        }
        else
        {
            let post_obj = {
                power_board: power_board_id,
                comment: additional_comment,
                user: company_admin.id
            }

            requestHandler.post(
                resourceHandler.create_power_board_note(),
                post_obj,
                onFormSubmitSuccess,
                onFailure);
        }
    };
    const onPowerBoardCreationSuccess = (response, additional_comments) =>
    {
        let power_board_id = response.data.id;
        let post_obj = {
            power_board: power_board_id,
            comment: "Initial entry manually entered",
            user: company_admin.id
        };

        const onSuccess = (response) =>
        {
            onInitialNoteSuccess(response, power_board_id, additional_comments);
        };
        requestHandler.post(
            resourceHandler.create_power_board_note(),
            post_obj,
            onSuccess,
            onFailure);
    }
    const onFormSubmitted = (post_obj, additional_comments) =>
    {
        const onSuccess = (response) =>
        {
            onPowerBoardCreationSuccess(response, additional_comments);
        };

        requestHandler.post(
            resourceHandler.create_power_board(),
            post_obj,
            onSuccess,
            onFailure);

        setSubmittingForm(true);
    }

    if(error || apiError)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(redirectToInventory)
    {
        return ( <Navigate to="/inventory_management/"/>);
    }
    else if(creationSuccessful)
    {
        return (
            <div>
                <h1> Power Board Creation Success </h1>
                <p> Press the button below to return to inventory </p>
                <p> You may also navigate to any other page using the banner above </p>
                <Button
                    variant="primary"
                    className="BigButton"
                    onClick={onRedirectInventory}>
                        Inventory Management
                </Button>
            </div>
        );
    }
    else if(submittingForm)
    {
        return <ResourceLoader />;
    }
    else if(loaded)
    {
        let data = {
            company_admin: company_admin,
            cabinets: cabinets,
            power_boards: power_boards,
        };

        return (
            <PowerBoardCreationForm
                data={data}
                onSubmit={onFormSubmitted}
                buttonText={"Create New Power Board"}
            />
        );
    }
    else
    {
        return <ResourceLoader />;
    }
}
