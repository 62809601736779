import { Table, Form }  from 'react-bootstrap'

export default function RecordingLayoutForm(props)
{
    const setSelectedInsInitType = (event) =>
    {
        props.setSelectedInsInitType(event.target.value);
    }
    const setRecordingActiveSides = (event) =>
    {
        props.setRecordingActiveSides(event.target.value);
    }
    const setEnableThreeGunProcessing = (event) =>
    {
        props.setEnableThreeGunProcessing(event.target.value);
    }
    const setLayoutActiveSides = (event) =>
    {
        props.setLayoutActiveSides(event.target.value);
    }

    return (
        <div>
            <h3> Recording/Layout Information </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            INS
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedInsInitType}
                                as="select"
                                name="ins"
                                onChange={setSelectedInsInitType}
                                size="md">
                                <option value={'DISABLED'}>
                                    Disabled
                                </option>
                                <option value={'CERTUS'}>
                                    Advanced Navigation Certus
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Recording Side(s)
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.recordingActiveSides}
                                as="select"
                                onChange={setRecordingActiveSides}
                                size="md">
                                <option value={'LEFT'}>
                                    Left
                                </option>
                                <option value={'RIGHT'}>
                                    Right
                                </option>
                                <option value={'BOTH'}>
                                    Both
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Three Gun Processing
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.enableThreeGunProcessing}
                                as="select"
                                onChange={setEnableThreeGunProcessing}
                                size="md">
                                <option value={'DISABLED'}>
                                    Disabled
                                </option>
                                <option value={'ENABLED'}>
                                    Enabled
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Layout Side(s)
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.layoutActiveSides}
                                as="select"
                                onChange={setLayoutActiveSides}
                                size="md">
                                <option value={'LEFT'}>
                                    Left
                                </option>
                                <option value={'RIGHT'}>
                                    Right
                                </option>
                                <option value={'BOTH'}>
                                    Both
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Form.Group>
        </div>
    );
}


