import axios from 'axios'

const REFRESH_URL = process.env.REACT_APP_BACKEND_URL + "token/refresh/"

/*
 * This function will intercept all requests and add a token if neccessary
 */
axios.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem("accessToken");

        if(token && config.ignoreToken!==true)
        {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config
    },
    (error) => {
        Promise.reject(error)
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        let refreshToken = sessionStorage.getItem("refreshToken");

        if(refreshToken &&
            error.response.status === 403 &&
            !originalRequest._retry)
        {
            originalRequest._retry = true;
            return axios.post(
                REFRESH_URL,
                { refresh: refreshToken })
                .then((res) => {
                    if(res.status === 200)
                    {
                        sessionStorage.setItem("accessToken",
                            res.data.access);
                        return axios(originalRequest);
                    }
                })
        }

        return Promise.reject(error)
    });

// No shame in stack overflow grabbing
// https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
const download_file = (file_url, file_name, ignoreToken) =>
{
    axios({
        ignoreToken: ignoreToken,
        url: file_url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
    });
}

/*
 * This will get a single resource from the server
 * callbacks onResponse and onError will be triggered
 * appropriately
 */
const get = (resource, onResponse, onError, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {

        }
    };

    return axios.get(resource.url, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

const cacheFetcher = (url, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {

        }
    };

    return axios.get(url, config).then((res) => res.data);
}

const getAll = (resources, onResponses, onErrors, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
        }
    };

    let promises = []
    for(let i = 0; i < resources.length; i++)
    {
        promises.push(
            axios.get(resources[i].url, config));
    }

    axios.all(promises)
        .then(axios.spread((...responses) => {
            onResponses(responses);
        }))
        .catch(errors => {
            onErrors(errors);
        });
}

const post = (resource, post_obj, onResponse, onError, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.post(resource.url, post_obj, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

const postMultipart = (resource, post_obj, onResponse, onError, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    return axios.post(resource.url, post_obj, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

const postAll = (resources, post_objs, onResponses, onErrors, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };

    let promises = []
    for(let i = 0; i < resources.length; i++)
    {
        promises.push(
            axios.post(resources[i].url, post_objs[i], config));
    }

    axios.all(promises)
        .then(axios.spread((...responses) => {
            onResponses(responses);
        }))
        .catch(errors => {
            onErrors(errors);
        });
}

const put = (resource, put_obj, onResponse, onError, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.put(resource.url, put_obj, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

const putAll = (resources, put_objs, onResponses, onErrors, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };

    let promises = []
    for(let i = 0; i < resources.length; i++)
    {
        promises.push(
            axios.put(resources[i].url, put_objs[i], config));
    }

    axios.all(promises)
        .then(axios.spread((...responses) => {
            onResponses(responses);
        }))
        .catch(errors => {
            onErrors(errors);
        });
}

const patch = (resource, patch_obj, onResponse, onError, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.patch(resource.url, patch_obj, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

const patchAll = (resources, patch_objs, onResponses, onErrors, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };

    let promises = []
    for(let i = 0; i < resources.length; i++)
    {
        promises.push(
            axios.patch(resources[i].url, patch_objs[i], config));
    }

    axios.all(promises)
        .then(axios.spread((...responses) => {
            onResponses(responses);
        }))
        .catch(errors => {
            onErrors(errors);
        });
}

const del = (resource, onResponse, onError, ignoreToken) =>
{
    let config = {
        ignoreToken: ignoreToken,
        headers: {

        }
    };

    return axios.delete(resource.url, config)
        .then(
            (response) => {
                onResponse(response);
            })
        .catch(
            (error) => {
                onError(error);
            });
}

export const requestHandler =
{
    download_file,
    get,
    cacheFetcher,
    getAll,
    post,
    postMultipart,
    postAll,
    put,
    putAll,
    patch,
    patchAll,
    del
}
