export default function getToken() {
    const refreshToken = sessionStorage.getItem('refreshToken')
    const accessToken = sessionStorage.getItem('accessToken')

    if(refreshToken)
    {
        return {
            refresh: refreshToken,
            access: accessToken
        }
    }
    else
    {
        return null
    }
}
