import React, {useState} from 'react';
import { Navbar, NavLink, Nav } from 'react-bootstrap'
import { Link, NavLink as NLink } from 'react-router-dom'
import {useSWRConfig} from 'swr'

import logo from './limntech_logo.png'

import './bootstrap/css/bootstrap.css'

function UserNavbar(props)
{
    /*
     * Data Loading
     */
    const [invalidateToken, setInvalidateToken] = useState(false);
    const { cache } = useSWRConfig();

    if(invalidateToken)
    {
        props.setToken(null);
        cache.clear();
    }

    /*
     * UI Logic
     */
    const invalidate = () =>
    {
        setInvalidateToken(true);
    }

    const render = () =>
    {
        return(
            <Navbar expand="lg" bg="light" variant="light">
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} style={{maxWidth: 115}} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                <NavLink as={NLink} to="/inventory_management/">Inventory Management</NavLink>
                <NavLink as={NLink} to="/truck_setup/">Truck Setup</NavLink>
                </Nav>
                <Nav className="navbar-right">
                <NavLink as={Link} to="/" onClick={invalidate}>Logout</NavLink>
                </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

    /*
     * Render Logic
     */
    return render();
};

function LoggedOutNavbar(props)
{
    return(
        <Navbar bg="light" variant="light">
            <Navbar.Brand as={Link} to="/">
                <img src={logo} style={{maxWidth: 115}} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Nav className="mr-auto">
            </Nav>
        </Navbar>
    );

};

export { UserNavbar, LoggedOutNavbar }
