export default function defaultLifemarkConfiguration()
{
    const default_config_file =
    {
    DevMode: true,
    FormatVersion: 3,
    VehicleName: "",
    Hardware: {
        EnableStartupSleep: true,
        AnalogInput: {
            InitType: 0
        },
        CanBus: {
            BaudRate: "500KB",
            BoardNumber: 0,
            CanLine: 0,
            InitType: 0
        },
        CanBusMonitor: {
            InitType: 0
        },
        CarriageSide: {
            left: {
                CameraArray: {
                    Camera: {

                    },
                    CarriageCamera: "",
                    FrameRate: 20,
                    InitType: 0
                },
                CarriageAssembly: {
                    InitType: 10,
                    BeadGuns: {

                    },
                    PaintGuns: {

                    },
                    CarriageActuator: {
                        InitType: 11,
                        Motor: {
                            AccLowerRevS2: 0,
                            AccUpperRevS2: 0,
                            ExtendDegPerMM: 0,
                            LowerMM: 0,
                            RetractDegPerMM: 0,
                            UpperMM: 0,
                            VelocityLowerRevS: 0,
                            VelocityUpperRevS: 0
                        }
                    },
                    CarriageDistanceSensor: {
                        InitType: 11,
                        VoltageOutput: {
                            AiChannel: 0,
                            TransferFcnBias: 0.0,
                            TransferFcnSlope: 0.0
                        }
                    }
                },
                DigitalControl: {
                    InitType: 0,
                    LimnTechControl: {
                        DioDevices: 2,
                        DioPorts: 8,
                        LiftCarriageBitmask: 128,
                        LiftCarriageDeviceId: 2,
                        RelayControlBitmask: 128,
                        RelayControlDeviceId: 1
                    }
                }
            },
            right: {
                CameraArray: {
                    Camera: {

                    },
                    CarriageCamera: "",
                    FrameRate: 20,
                    InitType: 0
                },
                CarriageAssembly: {
                    InitType: 10,
                    BeadGuns: {

                    },
                    PaintGuns: {

                    },
                    CarriageActuator: {
                        InitType: 11,
                        Motor: {
                            AccLowerRevS2: 0,
                            AccUpperRevS2: 0,
                            ExtendDegPerMM: 0,
                            LowerMM: 0,
                            RetractDegPerMM: 0,
                            UpperMM: 0,
                            VelocityLowerRevS: 0,
                            VelocityUpperRevS: 0
                        }
                    },
                    CarriageDistanceSensor: {
                        InitType: 11,
                        VoltageOutput: {
                            AiChannel: 0,
                            TransferFcnBias: 0.0,
                            TransferFcnSlope: 0.0
                        }
                    }
                },
                DigitalControl: {
                    InitType: 0,
                    LimnTechControl: {
                        DioDevices: 2,
                        DioPorts: 8,
                        LiftCarriageBitmask: 128,
                        LiftCarriageDeviceId: 2,
                        RelayControlBitmask: 128,
                        RelayControlDeviceId: 1
                    }
                }
            }
        },
        Daq: {
            InitType: 0,
            SampleRateHz: 0,
            DaqChannel: {

            }
        },
        Ins: {
            InitType: 0,
            Serial: {
                AuxiliaryBaudRate: 115200,
                AuxiliaryPortName: "/dev/tty/USB1",
                PrimaryBaudRate: 115200,
                PrimaryPortName: "/dev/tty/USB0"
            },
            Tcp: {
                AuxiliaryIp: "certus.local",
                AuxiliaryPort: 16719,
                PrimaryIp: "certus.local",
                PrimaryPort: 16718
            }
        },
        Odometry: {
            DistancePerPulseM: 0,
            InitType: 10
        },
        Power: {
            CommunicationBaudRate: 9600,
            CommunicationPort: "/dev/ttyS1",
            InitType: 10,
            UpdatePort: "/dev/ttyACM0"
        },
        SkiplineControlSystem: {
            BaudRate: "250KB",
            BoardNumber: 1,
            CanLine: 0,
            FwInitType: 10,
            InitType: 0
        }
    },
    Routine: {
        Layout: {
            Enable: true,
            CarriageSide: {

            }
        },
        Recording: {
            Enable: true,
            EnableThreeGunProcessing: false,
            DefaultDoubleLineSpacingIn: 10.0,
            CarriageSide: {

            },
        },
        Restripe: {
            Enable: true,
            GunCutoffSpeedMph: 1.0,
            CarriageSide: {

            }
        },
        Synchronization: {
            Enable: false,
            Hostname: "https://api.limntech.com",
            Port: 443
        }
    },
    Service: {
        NetworkManager: {
            CellularStatusPollingPeriodS: 1,
            InitType: 0,
            LanSubnetId: "192.168.3.0",
            NetworkMonitorPollingPeriodS: 1
        },
        Ntrip: {
            InitType: 0
        },
        Ptp: {
            SyncOffsetThresholdNs: 1200,
            SyncTimeoutS: 1200,
            SyncWindowSize: 5000,
            UseExternalClock: false
        }
    },
    Truck: {
        CarriageSide: {
            left: {
                CarriageBufferM: 0.01,
                CarriageLiftLowerExtensionM: 0.305,
                CarriageRestBarExists: false,
                CarriageRetractionM: 0.00001,
                EnableAutoCarriageRetraction: true,
                EnableLiftLowerCarriage: false,
                MaxCarriageExtensionM: 1.215
            },
            right: {
                CarriageBufferM: 0.01,
                CarriageLiftLowerExtensionM: 0.305,
                CarriageRestBarExists: false,
                CarriageRetractionM: 0.00001,
                EnableAutoCarriageRetraction: true,
                EnableLiftLowerCarriage: false,
                MaxCarriageExtensionM: 1.215
            }
        },
        Joints: {
            10: {
                Name: "DS Carriage Origin",
                Parent: 255,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: -1.5708,
                    X: 0,
                    Y: 0,
                    Z: 0
                }
            },
            11: {
                Name: "DS Carriage Tip",
                Parent: 10,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 0.0,
                    X: 0,
                    Y: 0,
                    Z: 0
                }
            },
            20: {
                Name: "PS Carriage Origin",
                Parent: 255,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 1.5708,
                    X: 0,
                    Y: 0,
                    Z: 0
                }
            },
            21: {
                Name: "PS Carriage Tip",
                Parent: 20,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 0.0,
                    X: 0,
                    Y: 0,
                    Z: 0
                }
            }
        }
    },
    Ui: {
        EnableFrameRateDisplay: true,
        EnableGraphical: true,
        EnableMetric: false,
        Graphical: {
            EnableFullScreen: true,
            EnableOpenGl: true
        },
        Text: {
            EnableImageDisplay: true
        }
    },
    Vision: {
        EdgeIgnoreRoiHeight: 0,
        EdgeIgnoreRoiWidth: 0,
        EdgeIgnoreRoiTopLeftX: 0,
        EdgeIgnoreRoiTopLeftY: 0
    }
    };

    return default_config_file;
}
