import React from 'react';
import { Table, Button, Form } from 'react-bootstrap'

import './bootstrap/css/bootstrap.css'
import { requestHandler } from './api/request.js'
import { resourceHandler } from './api/resource.js'
import UnexpectedResponseCodeView from './api/UnexpectedResponseCodeView.js'

export default class LoginView extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            username: "",
            password: "",
            credential_error: null,
            login_error: null,
            unexpected_login_code: null,
        };

        this.handleLoginResponse = this.handleLoginResponse.bind(this);
        this.handleLoginError = this.handleLoginError.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onUsernameChange(event)
    {
        this.setState({
            username: event.target.value
        });
    }

    onPasswordChange(event)
    {
        this.setState({
            password: event.target.value
        });
    }

    handleSubmit(event)
    {
        if(this.state.username.length === 0)
        {
            alert("You must enter a username!");
        }
        else
        {
            let post_obj = {
                username: this.state.username,
                password: this.state.password
            };

            requestHandler.post(
                resourceHandler.login(),
                post_obj,
                this.handleLoginResponse,
                this.handleLoginError);
        }

        event.preventDefault();
    }

    handleLoginResponse(response)
    {
        if(response.status !==
            resourceHandler.login().expected_response)
        {
            this.setState({
                unexpected_login_code: response.status
             });
        }
        else
        {
            this.props.setToken(response.data);
        }
    }

    handleLoginError(error)
    {
        this.setState({
            login_error: true
        });
    }

    renderErrorMessage()
    {
        if(this.state.login_error)
        {
            return <p style={{color: 'red'}}> Error: There was a problem attempting to log on. Please verify the username and password are correct </p>
        }
        else
        {
            return <p></p>
        }
    }

    renderLoginView()
    {
        return (
            <div className="container-fluid text-center">
            <div className="row content">
            <div className="col-lg-6 text-left">
            <div id="content-container" className="container p-none">
            <h1> LimnTech Manufacturing Login </h1>
            <Form onSubmit={this.handleSubmit}>
                <Form.Group className="align-items-left">
                    <Table>
                        <tbody>
                        <tr>
                            <td><b>Username</b></td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={this.onUsernameChange}
                                    value={this.state.username}
                                    size="lg" />
                            </td>
                        </tr>
                        <tr>
                            <td><b>Password</b></td>
                            <td>
                                <Form.Control
                                    type="password"
                                    onChange={this.onPasswordChange}
                                    value={this.state.password}
                                    size="lg" />
                            </td>
                        </tr>
                    </tbody>
                    </Table>
                </Form.Group>
                <Button variant="primary" type="Login" size="lg">
                    Login
                </Button>
            </Form>
                { this.renderErrorMessage() }
            </div></div></div></div>
        );
    }

    render()
    {
        if(this.state.unexpected_login_code)
        {
            return (
                <UnexpectedResponseCodeView
                    response_code={this.state.unexpected_login_code}
                />
            );
        }
        else
        {
            return this.renderLoginView();
        }
    }
}
