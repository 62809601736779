import { Table, Form, Button }  from 'react-bootstrap'

export default function BeadGunForm(props)
{
    const changeGunName = idx =>(event) =>
    {
        let bead_guns = [ ...props.beadGuns ];
        bead_guns[idx].GunName = event.target.value;
        props.setBeadGuns(bead_guns);
    };
    const changeGunId = idx =>(event) =>
    {
        let bead_guns = [ ...props.beadGuns ];
        bead_guns[idx].GunId = event.target.value;
        props.setBeadGuns(bead_guns);
    };
    const addGun = () =>
    {
        let bead_guns = [ ...props.beadGuns ];
        bead_guns.push({
            GunName: "",
            GunId: "",
        })
        props.setBeadGuns(bead_guns);
    }
    const deleteGun = () =>
    {
        let bead_guns = [ ...props.beadGuns ];
        bead_guns.pop();
        props.setBeadGuns(bead_guns);
    }

    return (
        <div>
            <h3> { props.name } </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            Gun Name
                        </td>
                        <td>
                            Gun ID
                        </td>
                    </tr>
                    {
                        props.beadGuns.map(
                            (bead_gun, idx) =>
                        {
                            return (
                                <tr>
                                <td>
                                    <Form.Control
                                    value={bead_gun.GunName}
                                    onChange={changeGunName(idx)}
                                    placeholder="Enter Gun Name"
                                    size="md"/>
                                </td>
                                <td>
                                    <Form.Control
                                    value={bead_gun.GunId}
                                    onChange={changeGunId(idx)}
                                    placeholder="Enter Gun Id"
                                    size="md"/>
                                </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </Table>
            </Form.Group>
            <Button
                onClick={addGun}
                type="submit"
                variant="primary"
                size="lg">
                Add Gun
            </Button>
            { "    " }
            <Button
                onClick={deleteGun}
                type="submit"
                variant="primary"
                size="lg">
                Delete Gun
            </Button>
        </div>
    );

}


