import React, {useState} from 'react'

import { Navigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import CabinetList from './CabinetList.js'

import '../../BigButton.css'

export default function CabinetInventory(props)
{
    const [onRedirectToCreate, setOnRedirectToCreate] = useState(false);

    const onCreateNewCabinet = (event) =>
    {
        setOnRedirectToCreate(true);

        event.preventDefault();
    };

    if(onRedirectToCreate)
    {
        return ( <Navigate to="/inventory_management/cabinet/create/"/>);
    }
    else
    {
        return (
            <div>
            <p>{"\n\n"}</p>
            <CabinetList />
            <p>{"\n\n"}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onCreateNewCabinet}>
                    Create New Cabinet
            </Button>
            <p>{"\n\n"}</p>
            </div>
        );
    }
}
