import React from 'react'

import { Navigate } from 'react-router-dom'
import { useState} from 'react'
import { Button } from 'react-bootstrap'
import './bootstrap/css/bootstrap.css'

export default function PageNotFoundView(props)
{
    const [redirect, setRedirect] = useState(false);

    if(redirect === true)
    {
        return ( <Navigate to="/" /> );
    }

    return (
        <div className="container-fluid text-center">
        <div className="row content">
        <div className="col-lg-6 text-left">
        <div id="content-container" className="container p-none">
        <h2> Page Not Found! </h2>
        <p> We could not find the page you are looking for. Please navigate to the home page via the button below.
            {'\n'} If you continue to experience problems, please contact the LimnTech team!</p>
        <Button
            variant="primary"
            size="lg"
            onClick={() => setRedirect(true)}>
            Home
        </Button>
        </div></div></div></div>
    );
}
