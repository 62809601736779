import React, { useState } from 'react'

import useSWR from 'swr'
import { Button }  from 'react-bootstrap'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import ResourceLoader from '../../ResourceLoader.js'

import { requestHandler } from '../../api/request.js'
import { resourceHandler } from '../../api/resource.js'

import TruckInfoForm from './TruckInfoForm.js'

function TruckCreationForm(props)
{
    let update_channel_id = '';
    let resources_channel_id = '';
    if(props.data.lifemark_app_update_channel.length > 0)
    {
        update_channel_id = props.data.lifemark_app_update_channel[0].id
    }
    if(props.data.resources_update_channel.length > 0)
    {
        resources_channel_id = props.data.resources_update_channel[0].id
    }

    const [createSuccess, setCreateSuccess] = useState(false);

    /*
     * Truck Information
     */
    const [customerName, setCustomerName] = useState("")
    const [limntechName, setLimntechName] = useState("")
    const [truckType, setTruckType] = useState("")
    const [truckUserUsername, setTruckUserUsername] = useState("")
    const [truckUserPassword, setTruckUserPassword] = useState("")
    const [selectedLifemarkAppUpdateChannelId, setSelectedLifemarkAppUpdateChannelId] = useState(update_channel_id);
    const [resourcesUpdateChannelId, setResourcesUpdateChannelId] = useState(resources_channel_id);

    let truck_info = (
        <TruckInfoForm
            customerName={customerName}
            setCustomerName={setCustomerName}
            limntechName={limntechName}
            setLimntechName={setLimntechName}
            truckType={truckType}
            setTruckType={setTruckType}
            truckUserUsername={truckUserUsername}
            setTruckUserUsername={setTruckUserUsername}
            truckUserPassword={truckUserPassword}
            setTruckUserPassword={setTruckUserPassword}
            selectedLifemarkAppUpdateChannelId={selectedLifemarkAppUpdateChannelId}
            setSelectedLifemarkAppUpdateChannelId={setSelectedLifemarkAppUpdateChannelId}
            resourcesUpdateChannelId={resourcesUpdateChannelId}
            setResourcesUpdateChannelId={setResourcesUpdateChannelId}
            data={props.data}
        />
    );

    const addPaths = (truck, company_id, processed_path, proposed_path) =>
    {
        let patch_truck = {
            processedpath_set: [],
            proposedpath_set: [],
        }

        for(let i = 0; i < processed_path.data.length; i++)
        {
            patch_truck.processedpath_set.push(
                processed_path.data[i].id);
        }
        for(let i = 0; i < proposed_path.data.length; i++)
        {
            patch_truck.proposedpath_set.push(
                proposed_path.data[i].id);
        }

        const onSuccess = (response) =>
        {
            if(response.status !==
                resourceHandler.modify_truck("").expected_response)
            {
                alert('Could not assign paths to the truck')
            }
            else
            {
                setCreateSuccess(true);
            }
        }
        const onError = (error) =>
        {
            alert('Could not assign paths to truck');
        }

        requestHandler.patch(
            resourceHandler.modify_truck(truck.id),
            patch_truck,
            onSuccess,
            onError);
    }

    const createTruckUser = (truck, company_id, processed_path, proposed_path) =>
    {
        let new_truck_user = {
            username: truckUserUsername,
            first_name: '',
            last_name: '',
            email: '',
            company: company_id,
            user_password: truckUserPassword,
            truck: truck.id
        }

        const onSuccess = (response) =>
        {
            if(response.status !==
                resourceHandler.create_user().expected_response)
            {
                alert('Error Creating Truck User');
            }
            else
            {
                addPaths(truck, company_id, processed_path, proposed_path);
            }
        };
        const onError = (error) =>
        {
            alert('Error Creating Truck User');
        };

        requestHandler.post(
            resourceHandler.create_user(),
            new_truck_user,
            onSuccess,
            onError);
    }

    const loadPaths = (truck) =>
    {
        let company_id = '';
        for(let i = 0; i < props.data.company_list.length; i++)
        {
            if(props.data.company_list[i].name.includes('Test Company'))
            {
                company_id = props.data.company_list[i].id
            }
        }

        if(company_id === '')
        {
            alert('Test Company Does Not Exist')
        }
        else
        {
            const onSuccess = (responses) =>
            {
                for(let i = 0; i < responses.length; i++)
                {
                    if(responses[i].status !==
                        resourceHandler.list_proposed_paths("").expected_response)
                    {
                        alert("Received unexpected response when trying to create lifemark config file");
                        return;
                    }
                }
                let processedpath_response = responses[0];
                let proposedpath_response = responses[1];
                createTruckUser(
                    truck,
                    company_id,
                    processedpath_response,
                    proposedpath_response);
            }
            const onError = (error) =>
            {
                alert("An error occurred when creating the app configuration file");
            }

            let resources = [
                resourceHandler.list_active_paths(
                    company_id),
                resourceHandler.list_proposed_paths(company_id)
            ];
            requestHandler.getAll(
                resources,
                onSuccess,
                onError);
        }
    }

    const createTruck = () =>
    {
        let truck = {
            customer_name: customerName,
            limntech_name: limntechName,
            truck_type: truckType,
            is_active: true,
            lifemark_app_update_channel: selectedLifemarkAppUpdateChannelId,
            configuration_update_channel: resourcesUpdateChannelId
        }

        const successFcn = (response) =>
        {
            truck['id'] = response.data.id
            if(response.status !==
                resourceHandler.create_truck().expected_response)
            {
                alert("Received unexpected response when trying to create the truck");
            }
            else
            {
                loadPaths(truck);
            }
        }
        const errorFcn = (error) =>
        {
            alert("An error occurred creating the truck");
        }

        requestHandler.post(
            resourceHandler.create_truck(),
            truck,
            successFcn,
            errorFcn);
    }

    if(createSuccess === true)
    {
        return (
            <div>
                <h2> The truck was successfully created! </h2>
            </div>
        );
    }
    else
    {
        return (
            <div>
                {truck_info}
                <Button
                    onClick={() => createTruck()}
                    variant="primary"
                    size="lg">
                    Submit
                </Button>
                <p>{'\n\n'}</p>
            </div>
        );
    }
}

export default function NewTruckSetup(props)
{
    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: company_list, error: company_list_error } = useSWR(
        resourceHandler.list_companies().url, requestHandler.cacheFetcher);
    const { data: truck_list, error: truck_list_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: lifemark_app_update_channel, error: lifemark_app_update_channel_error } =
        useSWR(resourceHandler.list_lifemark_app_update_channels().url, requestHandler.cacheFetcher);
    const { data: resources_update_channel, error: resources_update_channel_error } =
        useSWR(resourceHandler.list_configuration_update_channels().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && company_list && truck_list && lifemark_app_update_channel && resources_update_channel);
    let error = !(!ca_error && !company_list_error && !truck_list_error && !lifemark_app_update_channel_error && !resources_update_channel_error);

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        let data = {
            company_admin: company_admin,
            company_list: company_list,
            truck_list: truck_list,
            lifemark_app_update_channel: lifemark_app_update_channel,
            resources_update_channel: resources_update_channel,
        };

        if(company_admin.company_admin_superuser)
        {
            return (
                <div className="container-fluid text-center">
                <div className="row content">
                <div className="col-lg-8 text-left">
                <div id="content-container" className="container p-none">
                <TruckCreationForm
                    data={data}
                />
                </div></div></div></div>
            );
        }
        else
        {
            return (
                <div>
                <h1> Welcome { company_admin.first_name } { company_admin.last_name }! </h1>
                <p> You do not have permission to use this website </p>
                </div>
            );
        }
    }
    else
    {
        return <ResourceLoader />
    }

}
