import React, { useState} from 'react'
import moment from 'moment'

import useSWR, { useSWRConfig } from 'swr'
import { Table, Button, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'


import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import BigTable from '../../components/BigTable.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

import '../../BigButton.css'

function CommentHistory(props)
{
    let data = [];
    let datetime_lookup = { }
    for(let i = 0; i < props.data.notes.length; i++)
    {
        let user = props.data.notes[i].user
        for(let j = 0; j < props.data.superusers.length; j++)
        {
            if(props.data.superusers[j].id === user)
            {
                user = props.data.superusers[j].username
                break;
            }
        }
        datetime_lookup[props.data.notes[i].date_created] =
            moment(props.data.notes[i].date_created, 'MMMM Do YYYY - hh:mm:ss A').valueOf();

        let obj = {
            date: moment(props.data.notes[i].date_created + 'Z').format(
                'MMMM Do YYYY - hh:mm:ss A'),
            user: user,
            note: props.data.notes[i].comment
        };

        data.push(obj);
    }

    let headers = [
        { title: "Date", field: "date", width: 3000, minWidth: 200,
            sortable: true, searchable: true, customSort: (a, b) =>
            {
                return datetime_lookup[a] - datetime_lookup[b]
            } },
        { title: "User", field: "user", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Note", field: "note", width: 3000, minWidth: 600,
            sortable: true, searchable: true },
    ];


    return <BigTable
        headers={headers}
        data={data}
        defaultSort={{
            'key': 'column-0',
            'order': 'desc'
        }}
        title={'Edit History'}
    />
}

function PowerBoardEditForm(props)
{
    const { data: notes, error: note_error } = useSWR(
        resourceHandler.list_power_board_notes(props.data.power_board.id).url,
        requestHandler.cacheFetcher);

    let loaded = (notes);
    let error = !(!note_error);

    const [serialNumber, setSerialNumber] = useState(props.data.power_board.serial_no)
    const [hardwareRevisionName, setHardwareRevisionName] = useState(props.data.power_board.hardware_revision);
    const [firmwareVersion, setFirmwareVersion] = useState(props.data.power_board.firmware_version);
    const [boardBroken, setBoardBroken] = useState(props.data.power_board.is_broken)
    const [cabinetAssignment, setCabinetAssignment] = useState(props.data.power_board.cabinet)
    const [additionalComments, setAdditionalComments] = useState('')

    /*
     * State update callbacks
     */
    const onSerialChange = (event) =>
    {
        setSerialNumber(event.target.value);
    };
    const onHardwareRevisionChange = (event) =>
    {
        setHardwareRevisionName(event.target.value);
    };
    const onFirmwareVersionChange = (event) =>
    {
        setFirmwareVersion(event.target.value);
    };
    const onBoardBroken = (event) =>
    {
        setBoardBroken(event.target.value);
    };
    const onCabinetAssignment = (event) =>
    {
        setCabinetAssignment(event.target.value);
    };
    const onAdditionalComments = (event) =>
    {
        setAdditionalComments(event.target.value);
    };
    const onEditPowerBoard = (event) =>
    {
        /*
         * Data checks
         */
        if(!parseInt(serialNumber))
        {
            alert('Serial number needs to be in the form of MMYYBN like 022312');
            return;
        }
        if(serialNumber.length !== 6)
        {
            alert('Serial number needs to be in the form of MMYYBN like 022312');
            return;
        }
        if(serialNumber !== props.data.power_board.serial_no)
        {
            for(let i = 0; i < props.data.power_boards.length; i++)
            {
                if(props.data.power_boards[i].serial_no === serialNumber)
                {
                    alert('The serial number you entered is already assigned to a power board!');
                    return;
                }
            }
        }
        if(hardwareRevisionName.length === 0)
        {
            alert('You must enter a hardware revision number!');
            return;
        }
        if(firmwareVersion.length === 0)
        {
            alert('You must enter a firmware version!')
            return;
        }

        let edit_post_obj = {
            serial_no: serialNumber,
            hardware_revision: hardwareRevisionName,
            firmware_version: firmwareVersion,
            cabinet: cabinetAssignment,
            is_broken: boardBroken,
            is_deleted: false
        }

        if(edit_post_obj.cabinet === '')
        {
            edit_post_obj.cabinet = null
        }

        let comment_post_obj = {
            power_board: props.data.power_board.id,
            comment: additionalComments,
            user: props.data.company_admin.id
        }

        props.onSubmit(edit_post_obj, comment_post_obj)
    };

    const cabinetOptionList = () =>
    {
        let unassigned_cabinet_list = []

        for(let i = 0; i < props.data.cabinets.length; i++)
        {
            let match = false;
            for(let j = 0; j < props.data.power_boards.length; j++)
            {
                if(props.data.power_boards[j].cabinet === props.data.cabinets[i].id)
                {
                    if(props.data.power_board.cabinet !== props.data.cabinets[i].id)
                    {
                        match = true;
                        break;
                    }
                }
            }

            if(!match)
            {
                unassigned_cabinet_list.push(props.data.cabinets[i]);
            }
        }

        return unassigned_cabinet_list.map(
            (cabinet, index) =>
        {
            return (
                <option value={cabinet.id}>
                    {cabinet.serial_no}
                </option>
            );
        });
    }



    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        let data = props.data;
        data['notes'] = notes;

        return (
            <div>
            <h1> Edit Power Board </h1>
            <Form.Group>
                <Table hover>
                    <tbody>
                        <tr>
                            <td> Serial Number </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onSerialChange}
                                    value={serialNumber}
                                    placeholder={"enter board's serial number"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Hardware Revision </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onHardwareRevisionChange}
                                    value={hardwareRevisionName}
                                    placeholder={"enter board's hardware revision (e.g LM_100_300_v1000)"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Firmware Version </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onFirmwareVersionChange}
                                    value={firmwareVersion}
                                    placeholder={"enter board's firmware version (e.g 1.4.1)"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Are there any known problems with the board? </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onBoardBroken}
                                    value={boardBroken}
                                    size={"lg"}>
                                    <option value={false}> No </option>
                                    <option value={true}> Yes </option>
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Cabinet Assignment </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onCabinetAssignment}
                                    value={cabinetAssignment}
                                    size={"lg"}>
                                    <option value={''}> Unassigned </option>
                                    { cabinetOptionList() }
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Additional Comments </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onAdditionalComments}
                                    value={additionalComments}
                                    placeholder={"This field is optional"}
                                    size={"lg"} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form.Group>
            <p>{'\n\n'}</p>
            <p><b> Note: Editing the hardware assignment in this form may invalidate the configuration file generated by the new truck setup survey </b></p>
            <CommentHistory data={data} />
            <p>{'\n\n'}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onEditPowerBoard}>
                    { "Edit Power Board" }
            </Button>
            <p>{'\n\n'}</p>
            </div>
        );
    }
    else
    {
        return <ResourceLoader />
    }
}

export default function PowerBoardEdit(props)
{
    let { id } = useParams();
    const [apiError, setApiError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [redirectToInventory, setRedirectToInventory] = useState(false);

    const { cache } = useSWRConfig()

    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: cabinets, error: cabinets_error } = useSWR(
        resourceHandler.list_cabinets('').url, requestHandler.cacheFetcher);
    const { data: power_boards, error: power_board_error } = useSWR(
        resourceHandler.list_power_boards().url, requestHandler.cacheFetcher);
    const { data: superusers, error: superuser_error } = useSWR(
        resourceHandler.list_superusers().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && cabinets && power_boards && superusers);
    let error = !(!ca_error && !cabinets_error && !power_board_error && !superuser_error);

    const onRedirectInventory = () =>
    {
        /*
         * Since this is just internal use, I don't want to make the code more
         * complicated to update the cache on these changes. Lets just reload
         * the things we changed here
         */
        cache.delete(resourceHandler.list_power_boards().url)
        setRedirectToInventory(true);
    }
    const onSubmit = (edit_put_obj, comment_post_obj) =>
    {
        const onError = () =>
        {
            alert("ERROR");
            setApiError(true);
        }

        const onSuccess = () =>
        {
            setSubmitSuccess(true);
        }
        const onEditSuccess = (response) =>
        {
            if(comment_post_obj.comment.length === 0)
            {
                setSubmitSuccess(true);
            }
            else
            {
                requestHandler.post(
                    resourceHandler.create_power_board_note(),
                    comment_post_obj,
                    onSuccess,
                    onError);
            }
        }

        requestHandler.put(
            resourceHandler.edit_power_board(comment_post_obj.power_board),
            edit_put_obj,
            onEditSuccess,
            onError);
        setSubmitting(true)
    }

    if(error || apiError)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(redirectToInventory)
    {
        return ( <Navigate to="/inventory_management/"/>);
    }
    else if(submitSuccess)
    {
        return (
            <div>
                <h1> Power Board Edit Success </h1>
                <p> Press the button below to return to inventory </p>
                <p> You may also navigate to any other page using the banner above </p>
                <Button
                    variant="primary"
                    className="BigButton"
                    onClick={onRedirectInventory}>
                        Inventory Management
                </Button>
            </div>
        );
    }
    else if(submitting)
    {
        return <ResourceLoader/>
    }
    else if(loaded)
    {
        let power_board = null;
        for(let i = 0; i < power_boards.length; i++)
        {
            if(power_boards[i].id === id)
            {
                power_board = power_boards[i];
                break;
            }
        }

        if(power_board)
        {
            let data = {
                power_board: power_board,
                power_boards: power_boards,
                company_admin: company_admin,
                cabinets: cabinets,
                superusers: superusers
            };

            return <PowerBoardEditForm data={data} onSubmit={onSubmit}/>
        }
        else
        {
            return (
                <div>
                <h1> Something went wrong! </h1>
                <p> We could not find a match in the database for the selected power board </p>
                </div>
            );
        }
    }
    else
    {
        return <ResourceLoader />
    }
}
