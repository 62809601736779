import React from 'react'

import { Navigate } from 'react-router-dom'
import { useState} from 'react'
import { Button } from 'react-bootstrap'
import '../bootstrap/css/bootstrap.css'
import useToken from './useToken'

function renderGeneralErrorMessage()
{
    return (
        <div className="container-fluid text-center">
        <div className="row content">
        <div className="col-lg-6 text-left">
        <div id="content-container" className="container p-none">
        <h2> An error has occurred during the request! </h2>
        <p>
            Please attempt to navigate to the home page. If you continue to experience problems,
            please notify the LimnTech team.
        </p>
        </div></div></div></div>
    );
}

function invalidateToken(setToken, setRedirect)
{
    setToken(null);
    setRedirect(true);
}

function renderLogoutErrorMessage(setToken, setRedirect)
{

    return (
        <div className="container-fluid text-center">
        <div className="row content">
        <div className="col-lg-6 text-left">
        <div id="content-container" className="container p-none">
        <h2> Your session has expired! </h2>
        <p> Please log out, then log back in </p>
        <Button
            variant="primary"
            size="lg"
            onClick={() => invalidateToken(setToken, setRedirect)}>
            Logout
        </Button>
        </div></div></div></div>
    );
}

export default function ApiResponseErrorView(props)
{
    const [redirect, setRedirect] = useState(false);
    const { setToken } = useToken();

    if(redirect === true)
    {
        return ( <Navigate to="/" /> );
    }

    /*
     * Annoyingly axios throws an exception on a 401. This is a little hacky
     * but if we get the following message its because the access token expired
     */
    let token_expired_message = 'Request failed with status code 401'
    if(props.error.message === token_expired_message)
    {
        return renderLogoutErrorMessage(setToken, setRedirect);
    }
    else
    {
        return renderGeneralErrorMessage();
    }
}
