import { Circles } from "react-loader-spinner"

export default function ResourceLoader()
{
    let style = {
        position: 'absolute',
        width: '50%',
        height: '50%',
        top: '35%',
        left: '50%',
        transform: "translate(-50%, -50%)"
    };

    return (
        <div>
            <Circles
                height="100%"
                width="100%"
                wrapperStyle={style}
                visible={true}
                />
        </div>
    );
}
