/*
 * Todo: Import base url from config file
 */

const BASE_URL = process.env.REACT_APP_BACKEND_URL

const login = () =>
{
    return {
        url: BASE_URL + "token/",
        expected_response: 200
    };
}

const logout = () =>
{
    return {
        url: BASE_URL + "logout/",
        expected_response: 205
    };
}

const change_password = () =>
{
    return {
        url: BASE_URL + "rest-auth/password/change/",
        expected_response: 200
    };
}

const password_reset = () =>
{
    return {
        url: BASE_URL + "rest-auth/password/reset/",
        expected_response: 200
    };
}

const password_reset_confirm = () =>
{
    return {
        url: BASE_URL + "rest-auth/password/reset/confirm/",
        expected_response: 200
    };
}

const current_user = () =>
{
    return {
        url: BASE_URL + "lifemark_user_model/current_user/",
        expected_response: 200
    };
}

const list_active_paths = (company) =>
{
    let qs = "is_active=True&is_deleted=False&company="+company;
    return {
        url: BASE_URL + "processed_path/?" + qs,
        expected_response: 200
    };
}

const list_processed_paths = (company) =>
{
    let qs = "is_deleted=False&company="+company;
    return {
        url: BASE_URL + "processed_path/?" + qs,
        expected_response: 200
    };
}

const list_archived_paths = (company) =>
{
    let qs = "is_active=False&is_deleted=False&company="+company;
    return {
        url: BASE_URL + "processed_path/?" + qs,
        expected_response: 200
    };
}

const modify_processed_path = (path_id) =>
{
    return {
        url: BASE_URL + "processed_path/" + path_id + "/",
        expected_response: 200
    };
}

const get_processed_path_proof_of_work = (path_id) =>
{
    return {
        url: BASE_URL + "processed_path/pow/csv/" + path_id + "/",
        expected_response: 200
    };
}

const list_trucks = (company) =>
{
    let qs = ''
    if(company !== '')
    {
        qs = "company="+company;
    }

    return {
        url: BASE_URL + "truck/?"+qs,
        expected_response: 200
    };
}

const list_proposed_paths = (company) =>
{
    let qs = "company="+company

    return {
        url: BASE_URL + "proposed_path/?" + qs,
        expected_response: 200
    };
}

const get_single_processed_path = (path_id) =>
{
    return {
        url: BASE_URL + "processed_path/" + path_id + "/",
        expected_response: 200
    };
}

const modify_single_processed_path = (path_id) =>
{
    return {
        url: BASE_URL + "processed_path/" + path_id + "/",
        expected_response: 200
    };
}

const get_single_proposed_path = (path_id) =>
{
    return {
        url: BASE_URL + "proposed_path/" + path_id + "/",
        expected_response: 200
    };
}

const modify_single_proposed_path = (path_id) =>
{
    return {
        url: BASE_URL + "proposed_path/" + path_id + "/",
        expected_response: 200
    };
}

const delete_single_proposed_path = (path_id) =>
{
    return {
        url: BASE_URL + "proposed_path/" + path_id + "/",
        expected_response: 204
    };
}

const create_single_proposed_path = () =>
{
    return {
        url: BASE_URL + "proposed_path/",
        expected_response: 201
    };
}

const list_active_ntrip_credentials = (truck_id) =>
{
    let qs = "is_active=True&truck=" + truck_id;
    return {
        url: BASE_URL + "ntrip_credentials/?" + qs,
        expected_response: 200
    };
}

const list_ntrip_credentials = (truck_id) =>
{
    let qs = "truck=" + truck_id;
    return {
        url: BASE_URL + "ntrip_credentials/?" + qs,
        expected_response: 200
    };
}

const list_company_ntrip_credentials = (company) =>
{
    let qs = "company=" + company;
    return {
        url: BASE_URL + "ntrip_credentials/?" + qs,
        expected_response: 200
    }
}

const get_rtk_service = (id) =>
{
    return {
        url: BASE_URL + "rtk_service/" + id + "/",
        expected_response: 200
    };
}

const list_company_rtk_service = (company) =>
{
    let qs = "company=" + company;
    return {
        url: BASE_URL + "rtk_service/?" + qs,
        expected_response: 200
    };
}

const get_truck = (id) =>
{
    return {
        url: BASE_URL + "truck/" + id + "/",
        expected_response: 200
    };
}

const modify_truck = (id) =>
{
    return {
        url: BASE_URL + "truck/" + id + "/",
        expected_response: 200
    };
}

const create_truck = () =>
{
    return {
        url: BASE_URL + "truck/",
        expected_response: 201
    };
}

const modify_ntrip_credential = (id) =>
{
    return {
        url: BASE_URL + "ntrip_credentials/" + id + "/",
        expected_response: 200
    };
}

const get_company = (company_id) =>
{
    return {
        url: BASE_URL + "company/" + company_id + "/",
        expected_response: 200
    };
}

const list_lifemark_app_update_channels = () =>
{
    return {
        url: BASE_URL + "lifemark_app_update_channel/",
        expected_response: 200
    }
}

const list_configuration_update_channels = () =>
{
    return {
        url: BASE_URL + "global_configuration_update_channel/",
        expected_response: 200
    }
}

const list_companies = () =>
{
    return {
        url: BASE_URL + "company/",
        expected_response: 200
    };
}

const list_users = (company) =>
{
    let qs = "company="+company
    return {
        url: BASE_URL + "lifemark_user_model/?"+qs,
        expected_response: 200
    };
}

const list_superusers = () =>
{
    return {
        url: BASE_URL + "lifemark_user_model/?company_admin_superuser=True",
        expected_response: 200
    };
}

const get_user = (id) =>
{
    return {
        url: BASE_URL + "lifemark_user_model/" + id + "/",
        expected_response: 200
    };
}

const get_user_by_truck = (truck_id) =>
{
    return {
        url: BASE_URL + "lifemark_user_model/?truck=" + truck_id,
        expected_response: 200
    };
}

const get_user_by_username = (username) =>
{
    return {
        url: BASE_URL + "lifemark_user_model/?username=" + username,
        expected_response: 200
    };
}

const patch_user = (id) =>
{
    return {
        url: BASE_URL + "lifemark_user_model/" + id + "/",
        expected_response: 200
    };
}

const delete_user = (id) =>
{
    return {
        url: BASE_URL + "lifemark_user_model/delete/" + id + "/",
        expected_response: 204
    };
}

const create_user = () =>
{
    return {
        url: BASE_URL + "lifemark_user_model/create/",
        expected_response: 201
    };
}

const create_lifemark_configuration = () =>
{
    return {
        url: BASE_URL + "lifemark_configuration_file/",
        expected_response: 201
    }
}

const list_lifemark_configuration_files = (truck) =>
{
    return {
        url: BASE_URL + "lifemark_configuration_file/?truck=" + truck.id,
        expected_response: 200
    }
}

const create_power_board = () =>
{
    return {
        url: BASE_URL + "inventory/power_board/",
        expected_response: 201
    }
}

const list_power_boards = () =>
{
    return {
        url: BASE_URL + "inventory/power_board/",
        expected_response: 200
    }
}

const edit_power_board = (id) =>
{
    return {
        url: BASE_URL + "inventory/power_board/" + id + "/",
        expected_response: 200
    }
}

const create_power_board_note = () =>
{
    return {
        url: BASE_URL + "inventory/power_board_note/",
        expected_response: 201
    }
}

const list_power_board_notes = (id) =>
{
    return {
        url: BASE_URL + "inventory/power_board_note/?power_board=" + id,
        expected_response: 200
    }
}

const create_cabinet = () =>
{
    return {
        url: BASE_URL + "inventory/cabinet/",
        expected_response: 201
    }
}

const list_cabinets = () =>
{
    return {
        url: BASE_URL + "inventory/cabinet/",
        expected_response: 200
    }
}

const edit_cabinet = (id) =>
{
    return {
        url: BASE_URL + "inventory/cabinet/" + id + "/",
        expected_response: 200
    }
}

const create_cabinet_note = () =>
{
    return {
        url: BASE_URL + "inventory/cabinet_note/",
        expected_response: 201
    }
}

const list_cabinet_notes = (id) =>
{
    return {
        url: BASE_URL + "inventory/cabinet_note/?cabinet=" + id,
        expected_response: 200
    }
}

const create_camera = () =>
{
    return {
        url: BASE_URL + "inventory/camera/",
        expected_response: 201
    }
}

const list_cameras = () =>
{
    return {
        url: BASE_URL + "inventory/camera/",
        expected_response: 200
    }
}

const edit_camera = (id) =>
{
    return {
        url: BASE_URL + "inventory/camera/" + id + "/",
        expected_response: 200
    }
}

const create_camera_note = () =>
{
    return {
        url: BASE_URL + "inventory/camera_note/",
        expected_response: 201
    }
}

const list_camera_notes = (id) =>
{
    return {
        url: BASE_URL + "inventory/camera_note/?camera=" + id,
        expected_response: 200
    }
}

export const resourceHandler =
{
    login,
    logout,
    change_password,
    current_user,
    password_reset,
    password_reset_confirm,
    list_active_paths,
    list_archived_paths,
    list_processed_paths,
    modify_processed_path,
    list_trucks,
    list_proposed_paths,
    get_single_processed_path,
    modify_single_processed_path,
    get_processed_path_proof_of_work,
    get_single_proposed_path,
    modify_single_proposed_path,
    delete_single_proposed_path,
    create_single_proposed_path,
    list_active_ntrip_credentials,
    list_ntrip_credentials,
    list_company_ntrip_credentials,
    get_rtk_service,
    list_company_rtk_service,
    get_truck,
    modify_truck,
    create_truck,
    modify_ntrip_credential,
    get_company,
    list_lifemark_app_update_channels,
    list_configuration_update_channels,
    list_companies,
    list_users,
    list_superusers,
    get_user,
    get_user_by_truck,
    get_user_by_username,
    patch_user,
    delete_user,
    create_user,
    create_lifemark_configuration,
    list_lifemark_configuration_files,

    create_power_board,
    list_power_boards,
    edit_power_board,
    create_power_board_note,
    list_power_board_notes,

    create_cabinet,
    list_cabinets,
    edit_cabinet,
    create_cabinet_note,
    list_cabinet_notes,

    create_camera,
    list_cameras,
    edit_camera,
    create_camera_note,
    list_camera_notes,
}
