import { Table, Form }  from 'react-bootstrap'

export default function TruckInfoForm(props)
{
    const handleCustomerNameChange = (event) =>
    {
        props.setCustomerName(event.target.value);
    };
    const handleLimntechNameChange = (event) =>
    {
        props.setLimntechName(event.target.value);
    };
    const handleTruckTypeChange = (event) =>
    {
        props.setTruckType(event.target.value);
    };
    const setTruckUserUsername = (event) =>
    {
        props.setTruckUserUsername(event.target.value);
    };
    const setTruckUserPassword = (event) =>
    {
        props.setTruckUserPassword(event.target.value);
    };
    const setSelectedLifemarkAppUpdateChannelId = (event) =>
    {
        props.setSelectedLifemarkAppUpdateChannelId(event.target.value);
    };
    const setResourcesUpdateChannelId = (event) =>
    {
        props.setResourcesUpdateChannelId(event.target.value);
    };

    return (
        <div>
            <h3> General Truck Information </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            Customer Name
                        </td>
                        <td>
                            <Form.Control
                                name="customer_name"
                                value={props.customerName}
                                onChange={handleCustomerNameChange}
                                placeholder="e.g. Truck 134"
                                size="md"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            LimnTech Name
                        </td>
                        <td>
                            <Form.Control
                                name="limntech_name"
                                value={props.limntechName}
                                onChange={handleLimntechNameChange}
                                placeholder="e.g. Truck 1028"
                                size="md"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Truck Type
                        </td>
                        <td>
                            <Form.Control
                                name="truck_type"
                                value={props.truckType}
                                onChange={handleTruckTypeChange}
                                placeholder="e.g. Pickup"
                                size="md"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Truck User Username
                        </td>
                        <td>
                            <Form.Control
                                value={props.truckUserUsername}
                                onChange={setTruckUserUsername}
                                placeholder="e.g. truck1028 (NOTE: remeber to save this somewhere"
                                size="md"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Truck User Password
                        </td>
                        <td>
                            <Form.Control
                                value={props.truckUserPassword}
                                onChange={setTruckUserPassword}
                                placeholder="e.g. nv7*24!sV6 (NOTE: remember to save this somewhere)"
                                type="password"
                                size="md"/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "25%"}}>
                            Software Version
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedLifemarkAppUpdateChannelId}
                                onChange={setSelectedLifemarkAppUpdateChannelId}
                                as="select"
                                name="company_selection"
                                size="md">
                            {
                                props.data.lifemark_app_update_channel.map(
                                    (channel, idx) =>
                                {
                                    return <option value={channel.id} key={idx}>
                                        {channel.name}/{channel.version}@{channel.username}/{channel.channel}
                                    </option>
                                })
                            }
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "25%"}}>
                            Resources Version
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.resourcesUpdateChannelId}
                                onChange={setResourcesUpdateChannelId}
                                as="select"
                                name="company_selection"
                                size="md">
                            {
                                props.data.resources_update_channel.map(
                                    (channel, idx) =>
                                {
                                    return <option value={channel.id} key={idx}>
                                        {channel.channel_name}
                                    </option>
                                })
                            }
                            </Form.Control>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Form.Group>
        </div>
    );
}
