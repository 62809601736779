import React, { useState } from 'react'

import { Table, Form }  from 'react-bootstrap'
import useSWR from 'swr'

import NewTruckSetup from './NewTruckSetup.js'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

function TruckSelector(props)
{
    const [selectedTruck, setSelectedTruck] = useState('UNSELECTED')

    /*
     * Cabinet hardware
     */
    const [selectedCabinet, setSelectedCabinet] = useState(null)
    const [selectedPowerBoard, setSelectedPowerBoard] = useState(null)


    /*
     * Camera
     */
    const [leftNumberOfCameras, setLeftNumberOfCameras] = useState(0)
    const [leftFrontCameraId, setLeftFrontCameraId] = useState(null);
    const [leftCarriageCameraId, setLeftCarriageCameraId] = useState(null);
    const [rightNumberOfCameras, setRightNumberOfCameras] = useState(0)
    const [rightFrontCameraId, setRightFrontCameraId] = useState(null);
    const [rightCarriageCameraId, setRightCarriageCameraId] = useState(null);


    const truckOptions = () =>
    {
        let trucks_sorted = props.data.trucks.sort((a,b) => {
            return a.limntech_name.localeCompare(b.limntech_name);
        });

        return trucks_sorted.map(
            (truck, idx) =>
        {
            return (
                <option value={truck.id}>
                    { truck.limntech_name}
                </option>
            );
        });
    }
    const onSelectedTruck = (event) =>
    {
        let truck = null;
        for(let i = 0; i < props.data.trucks.length; i++)
        {
            let truck_ = props.data.trucks[i]
            if(truck_.id === event.target.value)
            {
                truck = truck_
                break;
            }
        }

        /*
         * Set hardcoded cabinet data
         */
        let cabinet = null;
        if(truck !== null)
        {
            for(let i = 0; i < props.data.cabinets.length; i++)
            {
                if(props.data.cabinets[i].truck === truck.id)
                {
                    cabinet = props.data.cabinets[i];
                    break;
                }
            }
        }

        let power_board = null
        if(cabinet !== null)
        {
            for(let i = 0; i < props.data.power_boards.length; i++)
            {
                if(props.data.power_boards[i].cabinet === cabinet.id)
                {
                    power_board = props.data.power_boards[i];
                    break;
                }
            }
        }

        /*
         * Set camera hardcoded data
         */
        let number_of_left_cameras = 0;
        let front_left_camera_id = null;
        let rear_left_camera_id = null;

        if(truck !== null)
        {
            for(let i = 0; i < props.data.cameras.length; i++)
            {
                let camera = props.data.cameras[i]
                if(camera.truck === truck.id)
                {
                    if(camera.side === 'left')
                    {
                        if(camera.location === 'front')
                        {
                            number_of_left_cameras = number_of_left_cameras + 1 
                            front_left_camera_id = camera.serial_no
                        }
                        else if(camera.location === 'rear')
                        {
                            number_of_left_cameras = number_of_left_cameras + 1 
                            rear_left_camera_id = camera.serial_no
                        }
                        else if(camera.location === null)
                        {
                            number_of_left_cameras = number_of_left_cameras + 1 
                            rear_left_camera_id = camera.serial_no
                        }
                    }
                }
            }
        }


        let number_of_right_cameras = 0;
        let front_right_camera_id = null;
        let rear_right_camera_id = null;

        if(truck !== null)
        {
            for(let i = 0; i < props.data.cameras.length; i++)
            {
                let camera = props.data.cameras[i]
                if(camera.truck === truck.id)
                {
                    if(camera.side === 'right')
                    {
                        if(camera.location === 'front')
                        {
                            number_of_right_cameras = number_of_right_cameras + 1
                            front_right_camera_id = camera.serial_no
                        }
                        else if(camera.location === 'rear')
                        {
                            number_of_right_cameras = number_of_right_cameras + 1
                            rear_right_camera_id = camera.serial_no
                        }
                        else if(camera.location === null)
                        {
                            number_of_right_cameras = number_of_right_cameras + 1
                            rear_right_camera_id = camera.serial_no
                        }
                    }
                }
            }
        }

        setSelectedCabinet(cabinet)
        setSelectedPowerBoard(power_board)
        setLeftNumberOfCameras(number_of_left_cameras)
        setLeftFrontCameraId(front_left_camera_id)
        setLeftCarriageCameraId(rear_left_camera_id)
        setRightNumberOfCameras(number_of_right_cameras)
        setRightFrontCameraId(front_right_camera_id);
        setRightCarriageCameraId(rear_right_camera_id)
        setSelectedTruck(event.target.value)

        event.preventDefault()
    }

    const newTruckSetup = () =>
    {
        if(selectedTruck === 'UNSELECTED')
        {
            return <div/>
        }
        else
        {
            let truck = null;
            for(let i = 0; i < props.data.trucks.length; i++)
            {
                let truck_ = props.data.trucks[i]
                if(truck_.id === selectedTruck)
                {
                    truck = truck_
                    break;
                }
            }

            return <NewTruckSetup
                truck={truck}
                cabinet={selectedCabinet}
                power_board={selectedPowerBoard}
                leftNumberOfCameras={leftNumberOfCameras}
                leftCarriageCameraId={leftCarriageCameraId}
                leftFrontCameraId={leftFrontCameraId}
                rightNumberOfCameras={rightNumberOfCameras}
                rightFrontCameraId={rightFrontCameraId}
                rightCarriageCameraId={rightCarriageCameraId}/>
        }
    }

    return (
        <div>
        <Form.Group className="align-items-left">
            <Table hover>
                <tbody>
                <tr>
                    <td>
                        Select Truck
                    </td>
                    <td style={{width: "75%"}}>
                        <Form.Control
                            value={selectedTruck}
                            as="select"
                            onChange={onSelectedTruck}
                            size="md">
                            <option value={'UNSELECTED'}>
                                Select Truck Below
                            </option>
                            { truckOptions() }
                        </Form.Control>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Form.Group>
        <p>{'\n\n'}</p>
        {newTruckSetup()}
        </div>
    );
}

export default function TruckList(props)
{
    /*
     * Data Loading
     */
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: cameras, error: camera_error } = useSWR(
        resourceHandler.list_cameras().url, requestHandler.cacheFetcher);
    const { data: power_boards, error: power_boards_error } = useSWR(
        resourceHandler.list_power_boards().url, requestHandler.cacheFetcher);
    const { data: cabinets, error: cabinet_error } = useSWR(
        resourceHandler.list_cabinets().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (trucks && cameras && power_boards && cabinets);
    let error = !(!trucks_error && !camera_error && !power_boards_error && !cabinet_error);

    let data = {
        trucks: trucks,
        cameras: cameras,
        power_boards: power_boards,
        cabinets: cabinets
    }

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        return (
            <div>
            <h1> Truck Configuration Survey </h1>
            <TruckSelector data={data} />
            </div>
        );
    }
    else
    {
        return <ResourceLoader/>
    }
}
