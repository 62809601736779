import { useState } from 'react';
import getToken from './getToken.js'

export default function useToken() {
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        if(!userToken)
        {
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
        }
        else
        {
            sessionStorage.setItem('accessToken', userToken.access);
            sessionStorage.setItem('refreshToken', userToken.refresh);
        }
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}
