import React, { useState } from 'react'

import useSWR from 'swr'
import md5 from 'md5-hash'
import { v4 as uuidv4 } from 'uuid'
import { Button }  from 'react-bootstrap'

import ApiResponseErrorView from '../..//api/ApiResponseErrorView.js'
import ResourceLoader from '../..//ResourceLoader.js'

import { requestHandler } from '../../api/request.js'
import { resourceHandler } from '../../api/resource.js'
import defaultLifemarkConfiguration from './defaultLifemarkConfiguration.js'

import TruckInfoForm from './TruckInfoForm.js'
import HardwareForm from './HardwareForm.js'
import RecordingLayoutForm from './RecordingLayoutForm.js'
import RestripeForm from './RestripeForm.js'
import PaintGunForm from './PaintGunForm.js'
import BeadGunForm from './BeadGunForm.js'

const FormData = require('form-data')

/*
 * Default Config File
 */
function TruckCreationForm(props)
{
    const default_config_file = defaultLifemarkConfiguration();
    const [createSuccess, setCreateSuccess] = useState(false);

    /*
     * Truck Information
     */
    const [selectedProductType, setSelectedProductType] = useState('LM400');
    const [selectedMeasurementSystem, setSelectedMeasurementSystem] = useState('IMPERIAL')
    const [selectedLimntechCanInitType, setSelectedLimntechCanInitType] = useState('IXXAT_PCIE');

    /*
     * Carriage Assembly
     */
    const [leftCarriageDistanceSensor, setLeftCarriageDistanceSensor] = useState('MHS')
    const [leftCarriageDistanceSensorAiChannel, setLeftCarriageDistanceSensorAiChannel] = useState('')
    const [leftCarriageDistanceSensorAiSlope, setLeftCarriageDistanceSensorAiSlope] = useState('')
    const [leftCarriageActuator, setLeftCarriageActuator] = useState('VALVE')
    const [rightCarriageDistanceSensor, setRightCarriageDistanceSensor] = useState('MHS')
    const [rightCarriageDistanceSensorAiChannel, setRightCarriageDistanceSensorAiChannel] = useState('')
    const [rightCarriageDistanceSensorAiSlope, setRightCarriageDistanceSensorAiSlope] = useState('')
    const [rightCarriageActuator, setRightCarriageActuator] = useState('VALVE')
    const [liftLowerCarriageEnabled, setLiftLowerCarriageEnabled] = useState('ENABLED')
    const [carriageRestBarExists, setCarriageRestBarExists] = useState('YES')

    /*
     * Recording
     */
    const [selectedInsInitType, setSelectedInsInitType] = useState('CERTUS');
    const [recordingActiveSides, setRecordingActiveSides] = useState('BOTH')
    const [enableThreeGunProcessing, setEnableThreeGunProcessing] = useState('DISABLED')
    const [layoutActiveSides, setLayoutActiveSides] = useState('BOTH')

    /*
     * Restripe
     */
    const [selectedPaintControlSystem, setSelectedPaintControlSystem] = useState('DISABLED');
    const [sc12Type, setSc12Type] = useState('DUAL_OPERATOR')
    const [selectedDigitalCounter, setSelectedDigitalCounter] = useState('DISABLED');
    const [restripeActiveSides, setRestripeActiveSides] = useState('BOTH')

    /*
     * Left Paint Guns
     */
    const [leftPaintGuns, setLeftPaintGuns] = useState([]);
    const [leftBeadGuns, setLeftBeadGuns] = useState([]);
    const [rightPaintGuns, setRightPaintGuns] = useState([]);
    const [rightBeadGuns, setRightBeadGuns] = useState([]);

    let truck_info = (
        <TruckInfoForm
            selectedProductType={selectedProductType}
            setSelectedProductType={setSelectedProductType}
            selectedMeasurementSystem={selectedMeasurementSystem}
            setSelectedMeasurementSystem={setSelectedMeasurementSystem}
            data={props.data}
        />
    );
    let hardware = (
        <HardwareForm
            data={props.data}

            selectedLimntechCanInitType={selectedLimntechCanInitType}
            setSelectedLimntechCanInitType={setSelectedLimntechCanInitType}

            leftNumberOfCameras={props.leftNumberOfCameras}
            leftFrontCameraId={props.leftFrontCameraId}
            leftCarriageCameraId={props.leftCarriageCameraId}

            rightNumberOfCameras={props.rightNumberOfCameras}
            rightFrontCameraId={props.rightFrontCameraId}
            rightCarriageCameraId={props.rightCarriageCameraId}

            leftCarriageDistanceSensor={leftCarriageDistanceSensor}
            setLeftCarriageDistanceSensor={setLeftCarriageDistanceSensor}
            leftCarriageDistanceSensorAiChannel={leftCarriageDistanceSensorAiChannel}
            setLeftCarriageDistanceSensorAiChannel={setLeftCarriageDistanceSensorAiChannel}
            leftCarriageDistanceSensorAiSlope={leftCarriageDistanceSensorAiSlope}
            setLeftCarriageDistanceSensorAiSlope={setLeftCarriageDistanceSensorAiSlope}
            leftCarriageActuator={leftCarriageActuator}
            setLeftCarriageActuator={setLeftCarriageActuator}

            rightCarriageDistanceSensor={rightCarriageDistanceSensor}
            setRightCarriageDistanceSensor={setRightCarriageDistanceSensor}
            rightCarriageDistanceSensorAiChannel={rightCarriageDistanceSensorAiChannel}
            setRightCarriageDistanceSensorAiChannel={setRightCarriageDistanceSensorAiChannel}
            rightCarriageDistanceSensorAiSlope={rightCarriageDistanceSensorAiSlope}
            setRightCarriageDistanceSensorAiSlope={setRightCarriageDistanceSensorAiSlope}
            rightCarriageActuator={rightCarriageActuator}
            setRightCarriageActuator={setRightCarriageActuator}

            liftLowerCarriageEnabled={liftLowerCarriageEnabled}
            setLiftLowerCarriageEnabled={setLiftLowerCarriageEnabled}
            carriageRestBarExists={carriageRestBarExists}
            setCarriageRestBarExists={setCarriageRestBarExists}
        />
    );

    let recording_layout = <div/>;
    let restripe = <div/>;
    let left_paint_gun = <div/>
    let left_bead_gun = <div/>
    let right_paint_gun = <div/>
    let right_bead_gun = <div/>

    if(selectedProductType === 'LM100' || selectedProductType === 'LM400')
    {
        recording_layout = (
            <RecordingLayoutForm
                selectedInsInitType={selectedInsInitType}
                setSelectedInsInitType={setSelectedInsInitType}
                recordingActiveSides={recordingActiveSides}
                setRecordingActiveSides={setRecordingActiveSides}
                enableThreeGunProcessing={enableThreeGunProcessing}
                setEnableThreeGunProcessing={setEnableThreeGunProcessing}
                layoutActiveSides={layoutActiveSides}
                setLayoutActiveSides={setLayoutActiveSides}
            />
        );
    }
    if(selectedProductType === 'LM300' || selectedProductType === 'LM400')
    {
        restripe = (
            <RestripeForm
                selectedPaintControlSystem={selectedPaintControlSystem}
                setSelectedPaintControlSystem={setSelectedPaintControlSystem}
                sc12Type={sc12Type}
                setSc12Type={setSc12Type}
                selectedDigitalCounter={selectedDigitalCounter}
                setSelectedDigitalCounter={setSelectedDigitalCounter}
                restripeActiveSides={restripeActiveSides}
                setRestripeActiveSides={setRestripeActiveSides}
            />
        );
        left_paint_gun = (
            <PaintGunForm
                name={"Left Paint Guns"}
                paintGuns={leftPaintGuns}
                setPaintGuns={setLeftPaintGuns}
            />
        );
        left_bead_gun = (
            <BeadGunForm
                name={"Left Bead Guns"}
                beadGuns={leftBeadGuns}
                setBeadGuns={setLeftBeadGuns}
            />
        );
        right_paint_gun = (
            <PaintGunForm
                name={"Right Paint Guns"}
                paintGuns={rightPaintGuns}
                setPaintGuns={setRightPaintGuns}
            />
        );
        right_bead_gun = (
            <BeadGunForm
                name={"Right Bead Guns"}
                beadGuns={rightBeadGuns}
                setBeadGuns={setRightBeadGuns}
            />
        );
    }

    const createAppConfigurationFile = (app_config) =>
    {
        let app_config_string = JSON.stringify(app_config, '', 4);
        const blob = new Blob([app_config_string], {type: 'text/plain'})
        let app_config_md5 = md5(app_config_string)

        const filename = 'app_configuration_fv' +
            app_config.FormatVersion.toString() + '_uv0.json';
        const new_id = uuidv4();

        let update_version = 0
        for(let i = 0; i < props.data.config_files.length; i++)
        {
            let config_file = props.data.config_files[i]
            if(config_file.format_version === app_config.FormatVersion)
            {
                if(config_file.update_version >= update_version)
                {
                    update_version = config_file.update_version + 1
                }
            }
        }
        /*
         * We need to post a multipart form for this to take
         */
        const formData = new FormData();
        formData.append('id', new_id);
        formData.append('truck', props.data.truck.id);
        formData.append('format_version', app_config.FormatVersion);
        formData.append('update_version', update_version);
        formData.append('md5_hash', app_config_md5)
        formData.append('upload_file', blob, filename);

        const onSuccess = (response) =>
        {
            if(response.status !==
                resourceHandler.create_lifemark_configuration().expected_response)
            {
                alert("Received unexpected response when trying to create lifemark config file");
            }
            else
            {
                setCreateSuccess(true);
            }
        }
        const onError = (error) =>
        {
            alert("An error occurred when creating the app configuration file");
        }
        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        requestHandler.postMultipart(
            resourceHandler.create_lifemark_configuration(),
            formData,
            onSuccess,
            onError);
    }

    const compileAppConfigFile = (create_models) =>
    {
        let config_file = { ...default_config_file };

        /*
         * General Configuration
         */
        config_file.VehicleName = props.data.truck.limntech_name;

        if(selectedMeasurementSystem === 'IMPERIAL')
        {
            config_file.Ui.EnableMetric = false;
        }
        else
        {
            config_file.Ui.EnableMetric = true;
        }

        if(selectedProductType === 'LM100')
        {
            config_file.Routine.Layout.Enable = true;
            config_file.Routine.Recording.Enable = true;
            config_file.Routine.Restripe.Enable = false;
        }
        else if(selectedProductType === 'LM300')
        {
            config_file.Routine.Layout.Enable = false;
            config_file.Routine.Recording.Enable = false;
            config_file.Routine.Restripe.Enable = true;
        }
        else
        {
            config_file.Routine.Layout.Enable = true;
            config_file.Routine.Recording.Enable = true;
            config_file.Routine.Restripe.Enable = true;
        }

        if(selectedLimntechCanInitType === 'IXXAT_PCIE')
        {
            config_file.Hardware.CanBus.InitType = 11;
        }
        else
        {
            config_file.Hardware.CanBus.InitType = 0;
        }

        /*
         * Left Cameras
         */
        if(props.leftNumberOfCameras === 1 || props.leftNumberOfCameras === 2)
        {
            config_file.Hardware.CarriageSide.left.CameraArray.CarriageCamera =
                props.leftCarriageCameraId;
            config_file.Hardware.CarriageSide.left.CameraArray.InitType = 10;
            config_file.Hardware.CarriageSide.left.CameraArray.Camera[props.leftCarriageCameraId] = {
                EnableLogging: true,
                EnableSampleLogging: true,
                Enabled: true,
                HasWideAngleLens: false,
                Name: "Carriage",
                Role: "Display"
            };
            config_file.Truck.Joints[30] = {
                Name: "Left Carriage Camera",
                Parent: 255,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 0.0,
                    X: 0.0,
                    Y: 0.0,
                    Z: 0.0
                }
            }
        }
        if(selectedProductType === 'LM100' || selectedProductType === 'LM400')
        {
            if(recordingActiveSides === 'LEFT' || recordingActiveSides === 'BOTH')
            {
                config_file.Routine.Recording.CarriageSide['left'] = {
                    Recorders: {
                        0: {
                            CameraId: props.leftCarriageCameraId,
                            JointId: 30,
                            Name: "Carriage Camera",
                            RecorderType: "Camera"
                        }
                    }
                }
            }
        }

        if(props.leftNumberOfCameras === 2)
        {
            config_file.Hardware.CarriageSide.left.CameraArray.Camera[props.leftFrontCameraId] = {
                EnableLogging: true,
                EnableSampleLogging: true,
                Enabled: true,
                HasWideAngleLens: true,
                Name: "Front",
                Role: "Control"
            };
            config_file.Truck.Joints[31] = {
                Name: "Left Front Camera",
                Parent: 255,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 0.0,
                    X: 0.0,
                    Y: 0.0,
                    Z: 0.0
                }
            }
        }

        /*
         * Right Cameras
         */
        if(props.rightNumberOfCameras === 1 || props.rightNumberOfCameras === 2)
        {
            config_file.Hardware.CarriageSide.right.CameraArray.CarriageCamera =
                props.rightCarriageCameraId;
            config_file.Hardware.CarriageSide.right.CameraArray.InitType = 10
            config_file.Hardware.CarriageSide.right.CameraArray.Camera[props.rightCarriageCameraId] = {
                EnableLogging: true,
                EnableSampleLogging: true,
                Enabled: true,
                HasWideAngleLens: false,
                Name: "Carriage",
                Role: "Display"
            };
            config_file.Truck.Joints[40] = {
                Name: "Right Carriage Camera",
                Parent: 255,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 0.0,
                    X: 0.0,
                    Y: 0.0,
                    Z: 0.0
                }
            }
        }
        if(selectedProductType === 'LM100' || selectedProductType === 'LM400')
        {
            if(recordingActiveSides === 'RIGHT' || recordingActiveSides === 'BOTH')
            {
                config_file.Routine.Recording.CarriageSide['right'] = {
                    Recorders: {
                        0: {
                            CameraId: props.rightCarriageCameraId,
                            JointId: 40,
                            Name: "Carriage Camera",
                            RecorderType: "Camera"
                        }
                    }
                }
            }
        }

        if(props.rightNumberOfCameras === 2)
        {
            config_file.Hardware.CarriageSide.right.CameraArray.Camera[props.rightFrontCameraId] = {
                EnableLogging: true,
                EnableSampleLogging: true,
                Enabled: true,
                HasWideAngleLens: true,
                Name: "Front",
                Role: "Control"
            };
            config_file.Truck.Joints[41] = {
                Name: "Right Front Camera",
                Parent: 255,
                Pose: {
                    Pitch: 0.0,
                    Roll: 0.0,
                    Yaw: 0.0,
                    X: 0.0,
                    Y: 0.0,
                    Z: 0.0
                }
            }
        }

        /*
         * Left Carriage Assembly
         */
        if(leftCarriageDistanceSensor === 'DISABLED')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.InitType = 0
        }
        else if(leftCarriageDistanceSensor === 'DWS')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.InitType = 10
        }
        else if(leftCarriageDistanceSensor === 'MHS')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.InitType = 11
        }
        else if(leftCarriageDistanceSensor === 'ANALOG-MICROCONTROL')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.InitType = 12
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.AiChannel =
                parseInt(leftCarriageDistanceSensorAiChannel);
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.TransferFcnSlope =
                parseFloat(leftCarriageDistanceSensorAiSlope);
            config_file.Hardware.AnalogInput.InitType = 10;
        }
        else if(leftCarriageDistanceSensor === 'ANALOG-DAT7015-V-0-10')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.InitType = 13
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.AiChannel =
                parseInt(leftCarriageDistanceSensorAiChannel);
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.TransferFcnSlope =
                parseFloat(leftCarriageDistanceSensorAiSlope);
            config_file.Hardware.AnalogInput.InitType = 11;
        }
        else if(leftCarriageDistanceSensor === 'ANALOG-DAT7015-C-4-20')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.InitType = 14
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.AiChannel =
                parseInt(leftCarriageDistanceSensorAiChannel);
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.TransferFcnSlope =
                parseFloat(leftCarriageDistanceSensorAiSlope);
            config_file.Hardware.AnalogInput.InitType = 11;
        }


        if(leftCarriageActuator === 'DISABLED')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageActuator.InitType = 0
        }
        else if(leftCarriageActuator === 'VALVE')
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.CarriageActuator.InitType = 11
        }

        /*
         * Right Carriage Assembly
         */
        if(rightCarriageDistanceSensor === 'DISABLED')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.InitType = 0
        }
        else if(rightCarriageDistanceSensor === 'DWS')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.InitType = 10
        }
        else if(rightCarriageDistanceSensor === 'MHS')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.InitType = 11
        }
        else if(rightCarriageDistanceSensor === 'ANALOG-MICROCONTROL')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.InitType = 12
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.AiChannel =
                parseInt(rightCarriageDistanceSensorAiChannel);
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.TransferFcnSlope =
                parseFloat(rightCarriageDistanceSensorAiSlope);
            config_file.Hardware.AnalogInput.InitType = 10;
        }
        else if(rightCarriageDistanceSensor === 'ANALOG-DAT7015-V-0-10')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.InitType = 13
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.AiChannel =
                parseInt(rightCarriageDistanceSensorAiChannel);
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.TransferFcnSlope =
                parseFloat(rightCarriageDistanceSensorAiSlope);
            config_file.Hardware.AnalogInput.InitType = 11;
        }
        else if(rightCarriageDistanceSensor === 'ANALOG-DAT7015-C-4-20')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.InitType = 14
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.AiChannel =
                parseInt(rightCarriageDistanceSensorAiChannel);
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageDistanceSensor.VoltageOutput.TransferFcnSlope =
                parseFloat(rightCarriageDistanceSensorAiSlope);
            config_file.Hardware.AnalogInput.InitType = 11;
        }

        if(rightCarriageActuator === 'DISABLED')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageActuator.InitType = 0
        }
        else if(rightCarriageActuator === 'VALVE')
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.CarriageActuator.InitType = 11
        }

        /*
         * General Carriage Assembly
         */
        let lift_lower_carriage = false;
        let carriage_rest_bar = false;
        if(liftLowerCarriageEnabled === 'ENABLED')
        {
            lift_lower_carriage = true;
        }
        if(carriageRestBarExists === 'YES')
        {
            carriage_rest_bar = true;
        }

        config_file.Truck.CarriageSide.left.CarriageRestBarExists = carriage_rest_bar;
        config_file.Truck.CarriageSide.left.EnableLiftLowerCarriage = lift_lower_carriage;
        config_file.Truck.CarriageSide.right.CarriageRestBarExists = carriage_rest_bar;
        config_file.Truck.CarriageSide.right.EnableLiftLowerCarriage = lift_lower_carriage;

        /*
         * Recording Information
         */
        if(selectedProductType === 'LM100' || selectedProductType === 'LM400')
        {
            if(selectedInsInitType === 'DISABLED')
            {
                config_file.Hardware.Ins.InitType = 0;
            }
            else if(selectedInsInitType === 'CERTUS')
            {
                config_file.Hardware.Ins.InitType = 11;
            }

            let three_gun_processing = false;
            let line_spacing_in = 10.0;
            if(enableThreeGunProcessing === 'ENABLED')
            {
                three_gun_processing = true;
                line_spacing_in = 16.0;
            }

            config_file.Routine.Recording.EnableThreeGunProcessing = three_gun_processing;
            config_file.Routine.Recording.DefaultDoubleLineSpacingIn = line_spacing_in;
            config_file.Service.Ntrip.InitType = 10;
            config_file.Service.Ptp.UseExternalClock = true;
        }

        /*
         * Layout Information
         */
        if(selectedProductType === 'LM100' || selectedProductType === 'LM400')
        {
            if(layoutActiveSides === 'LEFT' || layoutActiveSides === 'BOTH')
            {
                config_file.Routine.Layout.CarriageSide['left'] = {
                    CarriageOriginJointId: 10,
                    RollingAverageNum: 30
                }
            }
            if(layoutActiveSides === 'RIGHT' || layoutActiveSides === 'BOTH')
            {
                config_file.Routine.Layout.CarriageSide['right'] = {
                    CarriageOriginJointId: 20,
                    RollingAverageNum: 30
                }
            }
        }

        /*
         * Restripe Information
         */
        if(selectedProductType === 'LM300' || selectedProductType === 'LM400')
        {
            if(restripeActiveSides === 'LEFT' || restripeActiveSides === 'BOTH')
            {
                config_file.Routine.Restripe.CarriageSide['left'] = {
                    RollingAverageNum: 20
                }
            }
            if(restripeActiveSides === 'RIGHT' || restripeActiveSides === 'BOTH')
            {
                config_file.Routine.Restripe.CarriageSide['right'] = {
                    RollingAverageNum: 20
                }
            }
        }

        if(selectedPaintControlSystem === 'SC12')
        {
            config_file.Hardware.SkiplineControlSystem.InitType = 11;
            if(sc12Type === 'DUAL_OPERATOR')
            {
                config_file.Hardware.SkiplineControlSystem.FwInitType = 10;
            }
            else if(sc12Type === 'SINGLE_OPERATOR')
            {
                config_file.Hardware.SkiplineControlSystem.FwInitType = 11;
            }

            if(restripeActiveSides === 'LEFT' || restripeActiveSides === 'BOTH')
            {
                config_file.Hardware.CarriageSide.left.DigitalControl.InitType = 11;
            }
            if(restripeActiveSides === 'RIGHT' || restripeActiveSides === 'BOTH')
            {
                config_file.Hardware.CarriageSide.right.DigitalControl.InitType = 11;
            }
        }
        else if(selectedPaintControlSystem === 'LIMNTECH')
        {
            config_file.Hardware.SkiplineControlSystem.InitType = 0;
            if(restripeActiveSides === 'LEFT' || restripeActiveSides === 'BOTH')
            {
                config_file.Hardware.CarriageSide.left.DigitalControl.InitType = 10;
            }
            if(restripeActiveSides === 'RIGHT' || restripeActiveSides === 'BOTH')
            {
                config_file.Hardware.CarriageSide.left.DigitalControl.InitType = 10;
            }
        }
        else
        {
            config_file.Hardware.SkiplineControlSystem.InitType = 0;
        }

        if(selectedDigitalCounter === 'ENABLED')
        {
            config_file.Hardware.Odometry.InitType = 10;
        }
        else
        {
            config_file.Hardware.Odometry.InitType = 0;
        }

        /*
         * Left Paint Guns
         */
        for(let i = 0; i < leftPaintGuns.length; i++)
        {
            let bead_guns = []
            if(leftPaintGuns[i].BeadId.length > 0)
            {
                bead_guns.push({
                    Index: parseInt(leftPaintGuns[i].BeadId),
                    OffsetM: parseFloat(leftPaintGuns[i].BeadOffset)
                })
            }

            let obj = {
                GunId: parseInt(leftPaintGuns[i].GunId),
                Name: leftPaintGuns[i].GunName,
                Color: leftPaintGuns[i].GunColor,
                LineWidthIn: 4.0,
                OnDelayMs: 100,
                OffDelayMs: 100,
                BeadGuns: bead_guns,
                CalibratedOffsetIn: {
                    X: parseFloat(leftPaintGuns[i].X),
                    Y: parseFloat(leftPaintGuns[i].Y)
                }
            }

            config_file.Hardware.CarriageSide.left.CarriageAssembly.PaintGuns[i] = obj;
        }

        /*
         * Left Bead Guns
         */
        for(let i = 0; i < leftBeadGuns.length; i++)
        {
            config_file.Hardware.CarriageSide.left.CarriageAssembly.BeadGuns[i] = {
                GunId: leftBeadGuns[i].GunId,
                Name: leftBeadGuns[i].Name,
                OnDelayMs: 100,
                OffDelayMs: 200
            }
        }

        /*
         * Right Paint Guns
         */
        for(let i = 0; i < rightPaintGuns.length; i++)
        {
            let bead_guns = []
            if(rightPaintGuns[i].BeadId.length > 0)
            {
                bead_guns.push({
                    Index: parseInt(rightPaintGuns[i].BeadId),
                    OffsetM: parseFloat(rightPaintGuns[i].BeadOffset)
                })
            }

            let obj = {
                GunId: parseInt(rightPaintGuns[i].GunId),
                Name: rightPaintGuns[i].GunName,
                Color: rightPaintGuns[i].GunColor,
                LineWidthIn: 4.0,
                OnDelayMs: 100,
                OffDelayMs: 100,
                BeadGuns: bead_guns,
                CalibratedOffsetIn: {
                    X: parseFloat(rightPaintGuns[i].X),
                    Y: parseFloat(rightPaintGuns[i].Y)
                }
            }

            config_file.Hardware.CarriageSide.right.CarriageAssembly.PaintGuns[i] = obj;
        }

        /*
         * Right Bead Guns
         */
        for(let i = 0; i < rightBeadGuns.length; i++)
        {
            config_file.Hardware.CarriageSide.right.CarriageAssembly.BeadGuns[i] = {
                GunId: rightBeadGuns[i].GunId,
                Name: rightBeadGuns[i].Name,
                OnDelayMs: 100,
                OffDelayMs: 200
            }
        }

        if(create_models)
        {
            createAppConfigurationFile(config_file);
        }
        else
        {
            let string = JSON.stringify(config_file, null, 4);
            const url = window.URL.createObjectURL(new Blob([string]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'configuration_preview.json');
            document.body.appendChild(link);
            link.click();
        }
    };

    if(createSuccess === true)
    {
        return (
            <div>
                <h2> The truck was successfully created! </h2>
            </div>
        );
    }
    else
    {
        return (
            <div>
                {truck_info}
                {hardware}
                {recording_layout}
                {restripe}
                {left_paint_gun}
                <p>{"\n"}</p>
                {left_bead_gun}
                <p>{"\n"}</p>
                {right_paint_gun}
                <p>{"\n"}</p>
                {right_bead_gun}
                <hr/>
                <hr/>
                <hr/>
                <p style={{color:"red"}}><b> Be careful! User input verification is not fully built into this survey yet </b></p>
                <Button
                    onClick={() => compileAppConfigFile(true)}
                    variant="primary"
                    size="lg">
                    Submit
                </Button>
                {'  '}
                <Button
                    onClick={() => compileAppConfigFile(false)}
                    variant="primary"
                    size="lg">
                    Preview
                </Button>
                <p>{'\n\n'}</p>
            </div>
        );
    }
}

export default function NewTruckSetup(props)
{
    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: company_list, error: company_list_error } = useSWR(
        resourceHandler.list_companies().url, requestHandler.cacheFetcher);
    const { data: truck_list, error: truck_list_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: lifemark_app_update_channel, error: lifemark_app_update_channel_error } =
        useSWR(resourceHandler.list_lifemark_app_update_channels().url, requestHandler.cacheFetcher);
    const { data: resources_update_channel, error: resources_update_channel_error } =
        useSWR(resourceHandler.list_configuration_update_channels().url, requestHandler.cacheFetcher);
    const { data: lm_config_files, error: lm_config_files_error } = useSWR(
        resourceHandler.list_lifemark_configuration_files(props.truck).url, requestHandler.cacheFetcher)

    /*
     * Render Logic
     */
    let loaded = (company_admin && company_list && truck_list && lifemark_app_update_channel && resources_update_channel && lm_config_files);
    let error = !(!ca_error && !company_list_error && !truck_list_error && !lifemark_app_update_channel_error && !resources_update_channel_error && !lm_config_files_error);

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        let data = {
            company_admin: company_admin,
            company_list: company_list,
            truck_list: truck_list,
            lifemark_app_update_channel: lifemark_app_update_channel,
            resources_update_channel: resources_update_channel,
            truck: props.truck,
            cabinet: props.cabinet,
            power_board: props.power_board,
            config_files: lm_config_files
        };

        if(company_admin.company_admin_superuser)
        {
            return (
                <div className="container-fluid text-center">
                <div className="row content">
                <div className="col-lg-8 text-left">
                <div id="content-container" className="container p-none">
                <TruckCreationForm
                    data={data}
                    leftNumberOfCameras={props.leftNumberOfCameras}
                    leftCarriageCameraId={props.leftCarriageCameraId}
                    leftFrontCameraId={props.leftFrontCameraId}
                    rightNumberOfCameras={props.rightNumberOfCameras}
                    rightFrontCameraId={props.rightFrontCameraId}
                    rightCarriageCameraId={props.rightCarriageCameraId}
                />
                </div></div></div></div>
            );
        }
        else
        {
            return (
                <div>
                <h1> Welcome { company_admin.first_name } { company_admin.last_name }! </h1>
                <p> You do not have permission to use this website </p>
                </div>
            );
        }
    }
    else
    {
        return <ResourceLoader />
    }

}
