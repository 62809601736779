import React, { useState } from 'react';
import useSWR from 'swr'
import { Table, Form } from 'react-bootstrap'

import CabinetInventory from './cabinet/CabinetInventory.js'
import CameraInventory from './camera/CameraInventory.js'
import PowerBoardInventory from './power_board/PowerBoardInventory.js'

import ApiResponseErrorView from '../api/ApiResponseErrorView.js'
import ResourceLoader from '../ResourceLoader.js'
import { resourceHandler } from '../api/resource.js'
import { requestHandler } from '../api/request.js'

export default function InventoryManagement(props)
{
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);

    const [selectedHardware, setSelectedHardware] = useState('Cabinet')

    let loaded = (company_admin);
    let error = !(!ca_error);

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        if(company_admin.company_admin_superuser)
        {
            const onSelection = (event) =>
            {
                setSelectedHardware(event.target.value);

                event.preventDefault();
            }

            let panel = (<div/>);
            if(selectedHardware === 'Cabinet')
            {
                panel = <CabinetInventory company_admin={company_admin} />
            }
            else if(selectedHardware === 'Camera')
            {
                panel = <CameraInventory company_admin={company_admin} />
            }
            else if(selectedHardware === 'Power')
            {
                panel = <PowerBoardInventory company_admin={company_admin} />
            }

            return (
                    <div>
                    <h1> Inventory Management ({ selectedHardware })</h1>
                    <Table>
                        <tbody>
                        <tr>
                        <td style={{width: "25%"}}> Select Hardware </td>
                        <td style={{width: "75%"}}>
                        <Form.Control
                            value={selectedHardware}
                            as="select"
                            name="action_selection"
                            onChange={onSelection}
                            size="md">
                            <option value={'Cabinet'}>
                                Cabinet
                            </option>
                            <option value={'Camera'}>
                                Camera
                            </option>
                            <option value={'Power'}>
                                Power Board
                            </option>
                        </Form.Control>
                        </td>
                        </tr>
                    </tbody>
                    </Table>
                    { panel }
                    </div>
            );
        }
        else
        {
            return (
                <div>
                <h1> Welcome { company_admin.first_name } { company_admin.last_name }! </h1>
                <p> You do not have permission to use this website </p>
                </div>
            );
        }
    }
    else
    {
        return (
            <ResourceLoader/>
        );
    }
}
