import { Table, Form }  from 'react-bootstrap'

export default function RestripeForm(props)
{
    const setSelectedPaintControlSystem = (event) =>
    {
        props.setSelectedPaintControlSystem(event.target.value);
    }
    const setSc12Type = (event) =>
    {
        props.setSc12Type(event.target.value)
    }
    const setSelectedDigitalCounter = (event) =>
    {
        props.setSelectedDigitalCounter(event.target.value);
    }
    const setRestripeActiveSides = (event) =>
    {
        props.setRestripeActiveSides(event.target.value);
    }

    const sc12TypeForm = () =>
    {
        if(props.selectedPaintControlSystem === 'SC12')
        {
            return (
                <tr>
                    <td>
                        SC12 Type
                    </td>
                    <td style={{width: "75%"}}>
                        <Form.Control
                            value={props.sc12Type}
                            as="select"
                            onChange={setSc12Type}
                            size="md">
                            <option value={'SINGLE_OPERATOR'}>
                                Single Operator
                            </option>
                            <option value={'DUAL_OPERATOR'}>
                                Dual Operator
                            </option>
                        </Form.Control>
                    </td>
                </tr>
            );
        }
        else
        {
            return <></>
        }
    };

    return (
        <div>
            <h3> Restripe Information </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            Paint Control System
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedPaintControlSystem}
                                as="select"
                                name="pcs"
                                onChange={setSelectedPaintControlSystem}
                                size="md">
                                <option value={'DISABLED'}>
                                    Disabled
                                </option>
                                <option value={'LIMNTECH'}>
                                    LimnTech Paint Control Board
                                </option>
                                <option value={'SC12'}>
                                    Skipline SC12
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    { sc12TypeForm() }
                    <tr>
                        <td>
                            Digital Counter
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedDigitalCounter}
                                as="select"
                                name="counter"
                                onChange={setSelectedDigitalCounter}
                                size="md">
                                <option value={'DISABLED'}>
                                    Disabled
                                </option>
                                <option value={'ENABLED'}>
                                    Enabled
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Restripe Side(s)
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.restripeActiveSides}
                                as="select"
                                onChange={setRestripeActiveSides}
                                size="md">
                                <option value={'LEFT'}>
                                    Left
                                </option>
                                <option value={'RIGHT'}>
                                    Right
                                </option>
                                <option value={'BOTH'}>
                                    Both
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Form.Group>
        </div>
    );
}


