import { Table, Form }  from 'react-bootstrap'

export default function HardwareForm(props)
{
    const onCanInitTypeChange = (event) =>
    {
        props.setSelectedLimntechCanInitType(event.target.value);
    };
    const setLeftCarriageDistanceSensor = (event) =>
    {
        props.setLeftCarriageDistanceSensor(event.target.value);
    };
    const setLeftCarriageDistanceSensorAiChannel = (event) =>
    {
        props.setLeftCarriageDistanceSensorAiChannel(event.target.value);
    };
    const setLeftCarriageDistanceSensorAiSlope = (event) =>
    {
        props.setLeftCarriageDistanceSensorAiSlope(event.target.value);
    };
    const setLeftCarriageActuator = (event) =>
    {
        props.setLeftCarriageActuator(event.target.value);
    };
    const setRightCarriageDistanceSensor = (event) =>
    {
        props.setRightCarriageDistanceSensor(event.target.value);
    };
    const setRightCarriageDistanceSensorAiChannel = (event) =>
    {
        props.setRightCarriageDistanceSensorAiChannel(event.target.value);
    };
    const setRightCarriageDistanceSensorAiSlope = (event) =>
    {
        props.setRightCarriageDistanceSensorAiSlope(event.target.value);
    };
    const setRightCarriageActuator = (event) =>
    {
        props.setRightCarriageActuator(event.target.value);
    };
    const setLiftLowerCarriageEnabled = (event) =>
    {
        props.setLiftLowerCarriageEnabled(event.target.value);
    };
    const setCarriageRestBarExists = (event) =>
    {
        props.setCarriageRestBarExists(event.target.value);
    };
    const displayCabinetSelection = () =>
    {
        if(props.data.cabinet === null)
        {
            return <p style={{color:"red"}}><b> No cabinet is selected! Please select a cabinet from inventory! </b></p>
        }
        else
        {
            return <p><b> { props.data.cabinet.serial_no } </b></p>
        }
    }
    const displayPowerBoardSelection = () =>
    {
        if(props.data.cabinet === null)
        {
            return <p style={{color:"red"}}><b> No power board is selected! Assign a power board to a cabinet, then assign a cabinet to the truck! </b></p>
        }
        else if(props.data.power_board === null)
        {
            return <p style={{color:"red"}}><b> No power board is selected! Please assign a power board to cabinet { props.data.cabinet.serial_no }! </b></p>
        }
        else
        {
            return <p><b> { props.data.power_board.serial_no } </b></p>
        }
    }
    const displayLeftCameraSelection = () =>
    {
        const numberOfCameraDisplay = () =>
        {
            if(props.leftNumberOfCameras === 0)
            {
                return(
                    <tr>
                        <td>
                            Left Number Of Cameras
                        </td>
                        <td>
                            <p style={{color:"red"}}><b> There are no cameras assigned to the left side! You can assign cameras via the inventory management page! </b></p>
                        </td>
                    </tr>
                );
            }
            else
            {
                return(
                    <tr>
                        <td>
                            Left Number Of Cameras
                        </td>
                        <td>
                            <p><b> { props.leftNumberOfCameras } </b></p>
                        </td>
                    </tr>
                );
            }
        }
        const leftFrontCameraDisplay = () =>
        {
            if(props.leftFrontCameraId === null)
            {
                return (<></>)
            }
            else
            {
                return (
                    <tr>
                        <td>
                            Left Front Camera ID
                        </td>
                        <td>
                            <p><b>{ props.leftFrontCameraId }</b></p>
                        </td>
                    </tr>
                );
            }
        }
        const leftRearCameraDisplay = () =>
        {
            if(props.leftCarriageCameraId === null)
            {
                return (<></>)
            }
            else
            {
                return (
                    <tr>
                        <td>
                            Left Rear Camera ID
                        </td>
                        <td>
                            <p><b>{ props.leftCarriageCameraId }</b></p>
                        </td>
                    </tr>
                );
            }
        }

        return (
            <>
                { numberOfCameraDisplay() }
                { leftFrontCameraDisplay() }
                { leftRearCameraDisplay() }
            </>
        )
    }
    const displayRightCameraSelection = () =>
    {
        const numberOfCameraDisplay = () =>
        {
            if(props.rightNumberOfCameras === 0)
            {
                return(
                    <tr>
                        <td>
                            Right Number Of Cameras
                        </td>
                        <td>
                            <p style={{color:"red"}}><b> There are no cameras assigned to the right side! You can assign cameras via the inventory management page! </b></p>
                        </td>
                    </tr>
                );
            }
            else
            {
                return(
                    <tr>
                        <td>
                            Right Number Of Cameras
                        </td>
                        <td>
                            <p><b> { props.rightNumberOfCameras } </b></p>
                        </td>
                    </tr>
                );
            }
        }
        const rightFrontCameraDisplay = () =>
        {
            if(props.rightFrontCameraId === null)
            {
                return (<></>)
            }
            else
            {
                return (
                    <tr>
                        <td>
                            Right Front Camera ID
                        </td>
                        <td>
                            <p><b>{ props.rightFrontCameraId }</b></p>
                        </td>
                    </tr>
                );
            }
        }
        const rightRearCameraDisplay = () =>
        {
            if(props.rightCarriageCameraId === null)
            {
                return (<></>)
            }
            else
            {
                return (
                    <tr>
                        <td>
                            Right Rear Camera ID
                        </td>
                        <td>
                            <p><b>{ props.rightCarriageCameraId }</b></p>
                        </td>
                    </tr>
                );
            }
        }

        return (
            <>
                { numberOfCameraDisplay() }
                { rightFrontCameraDisplay() }
                { rightRearCameraDisplay() }
            </>
        )
    }

    const leftAnalogInputForm = () =>
    {
        if(props.leftCarriageDistanceSensor === 'ANALOG-MICROCONTROL' ||
            props.leftCarriageDistanceSensor === 'ANALOG-DAT7015-C-4-20' ||
            props.leftCarriageDistanceSensor === 'ANALOG-DAT7015-V-0-10')
        {
            return (
                <>
                <tr>
                    <td>
                        Left Analog Input Channel
                    </td>
                    <td>
                        <Form.Control
                            name="lcdsc"
                            value={props.leftCarriageDistanceSensorAiChannel}
                            onChange={setLeftCarriageDistanceSensorAiChannel}
                            placeholder="Enter Analog Input Channel Number (1 indexed)"
                            size="md"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Left Analog Input Slope
                    </td>
                    <td>
                        <Form.Control
                            name="lcdss"
                            value={props.leftCarriageDistanceSensorAiSlope}
                            onChange={setLeftCarriageDistanceSensorAiSlope}
                            placeholder="Enter Analog Input Channel Slope (stroke_length_m / sensor_range) = 0.12954"
                            size="md"/>
                    </td>
                </tr>
                </>
            );
        }
        else
        {
            return <></>
        }
    };

    const rightAnalogInputForm = () =>
    {
        if(props.rightCarriageDistanceSensor === 'ANALOG-MICROCONTROL' ||
            props.rightCarriageDistanceSensor === 'ANALOG-DAT7015-C-4-20' ||
            props.rightCarriageDistanceSensor === 'ANALOG-DAT7015-V-0-10')
        {
            return (
                <>
                <tr>
                    <td>
                        Right Analog Input Channel
                    </td>
                    <td>
                        <Form.Control
                            name="lcdsc"
                            value={props.rightCarriageDistanceSensorAiChannel}
                            onChange={setRightCarriageDistanceSensorAiChannel}
                            placeholder="Enter Analog Input Channel Number (1 indexed)"
                            size="md"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Right Analog Input Slope
                    </td>
                    <td>
                        <Form.Control
                            name="lcdss"
                            value={props.rightCarriageDistanceSensorAiSlope}
                            onChange={setRightCarriageDistanceSensorAiSlope}
                            placeholder="Enter Analog Input Channel Slope e.g. (stroke_length_m / sensor_range) = 0.12954"
                            size="md"/>
                    </td>
                </tr>
                </>
            );
        }
        else
        {
            return <></>
        }
    };


    return (
        <div>
            <h3> General Hardware </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            Assigned Cabinet
                        </td>
                        <td style={{width: "75%"}}>
                            { displayCabinetSelection() }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Assigned Power Board
                        </td>
                        <td style={{width: "75%"}}>
                            { displayPowerBoardSelection() }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Can Bus
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedLimntechCanInitType}
                                as="select"
                                name="can_bus"
                                onChange={onCanInitTypeChange}
                                size="md">
                                <option value={'DISABLED'}>
                                    Disabled
                                </option>
                                <option value={'IXXAT_PCIE'}>
                                    Ixxat PCIE
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    { displayLeftCameraSelection() }
                    { displayRightCameraSelection() }
                    <tr>
                        <td>
                            Left Carriage Distance Sensor
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.leftCarriageDistanceSensor}
                                as="select"
                                onChange={setLeftCarriageDistanceSensor}
                                size="md">
                                <option value={'DWS'}>
                                    Draw Wire Sensor
                                </option>
                                <option value={'MHS'}>
                                    Smart Cylinder
                                </option>
                                <option value={'ANALOG-MICROCONTROL'}>
                                    Microcontrol Voltage/Current Sensor
                                </option>
                                <option value={'ANALOG-DAT7015-V-0-10'}>
                                    DAT7015 Voltage (0-10V)
                                </option>
                                <option value={'ANALOG-DAT7015-C-4-20'}>
                                    DAT7015 Current (4-20mA)
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    { leftAnalogInputForm() }
                    <tr>
                        <td>
                            Left Carriage Actuator
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.leftCarriageActuator}
                                as="select"
                                onChange={setLeftCarriageActuator}
                                size="md">
                                <option value={'VALVE'}>
                                    Hydraulic Valve
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Right Carriage Distance Sensor
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.rightCarriageDistanceSensor}
                                as="select"
                                onChange={setRightCarriageDistanceSensor}
                                size="md">
                                <option value={'DWS'}>
                                    Draw Wire Sensor
                                </option>
                                <option value={'MHS'}>
                                    Smart Cylinder
                                </option>
                                <option value={'ANALOG-MICROCONTROL'}>
                                    Microcontrol Voltage/Current Sensor
                                </option>
                                <option value={'ANALOG-DAT7015-V-0-10'}>
                                    DAT7015 Voltage (0-10V)
                                </option>
                                <option value={'ANALOG-DAT7015-C-4-20'}>
                                    DAT7015 Current (4-20mA)
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    { rightAnalogInputForm() }
                    <tr>
                        <td>
                            Right Carriage Actuator
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.rightCarriageActuator}
                                as="select"
                                onChange={setRightCarriageActuator}
                                size="md">
                                <option value={'VALVE'}>
                                    Hydraulic Valve
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Lift Lower Carriage Enabled
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.liftLowerCarriageEnabled}
                                as="select"
                                onChange={setLiftLowerCarriageEnabled}
                                size="md">
                                <option value={'DISABLED'}>
                                    Disabled
                                </option>
                                <option value={'ENABLED'}>
                                    Enabled
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Carriage Rest Bar Exists
                        </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.carriageRestBarExists}
                                as="select"
                                onChange={setCarriageRestBarExists}
                                size="md">
                                <option value={'NO'}>
                                    No
                                </option>
                                <option value={'YES'}>
                                    Yes
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Form.Group>
        </div>
    );
}


