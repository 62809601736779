import React, {useState} from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css'
import styled from '@emotion/styled'
import { Grid, TextField } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const generateColumns = (
  column_desc,
  prefix: string = "column-"
) =>
{
  return new Array(column_desc.length).fill(0).map((column, columnIndex) => ({
    key: `${prefix}${columnIndex}`,
    dataKey: `${prefix}${columnIndex}`,
    title: column_desc[columnIndex].title,
    width: column_desc[columnIndex].width,
    field: column_desc[columnIndex].field,
    minWidth: column_desc[columnIndex].minWidth,
    sortable: column_desc[columnIndex].sortable,
    customSort: column_desc[columnIndex].customSort,
    fixed: false
  }));
}

const generateData = (
  columns: ReturnType<typeof generateColumns>,
  data,
  prefix = "row-"
) =>
{
  return new Array(data.length).fill(0).map((row, rowIndex) => {
    return columns.reduce(
      (rowData: Record<string, any>, column, columnIndex) => {
        let obj_ = data[rowIndex];
        rowData[column.dataKey] = obj_[column.field];
        return rowData;
      },
      {
        id: `${prefix}${rowIndex}`
      }
    );
  });
}


const Container = styled.div`
  width: calc(98vw);
  height: 30vh;
  width: 100vw;

  .BaseTable__header-row {
    background: #fff;
  }
`


export default function BigTable(props)
{
    let data_ = []
    const [sortBy, setSortBy] = useState(props.defaultSort);
    const [searchValue, setSearchValue] = useState("")

    /*
     * Remove columns based on the search value
     */
    if(searchValue !== "")
    {
        for(let i = 0; i < props.data.length; i++)
        {
            let found = false;
            let row = props.data[i];

            for(let j = 0; j < props.headers.length; j++)
            {
                if(props.headers[j].searchable === true)
                {
                    if(row[props.headers[j].field] === null)
                    {
                        continue
                    }
                    if(row[props.headers[j].field].includes(searchValue))
                    {
                        data_.push(row);
                        found = true;
                        break;
                    }
                }
            }

            if(found)
            {
                continue;
            }
        }
    }
    else
    {
        data_ = props.data;
    }

    const columns = generateColumns(props.headers)
    const data = generateData(columns, data_)

    let order = sortBy['order']
    let key = sortBy['key']

    let sortFcn = (a, b) =>
    {
        if(a > b)
        {
            return 1;
        }
        else if(a === b)
        {
            return 0;
        }
        else
        {
            return -1;
        }
    };

    /*
     * Determine the function to sort by. The user can
     * pass in a custom sort function. This will handle that
     */
    for(let i = 0; i < columns.length; i++)
    {
        let column = columns[i]
        if(column['key'] === key)
        {
            if(column.customSort)
            {
                sortFcn = (a, b) =>
                {
                    let val = column.customSort(a,b);
                    return val;
                }
            }
        }
    }

    const onSearch = (event) =>
    {
        setSearchValue(event.target.value);
    }
    const dataSorted = [...data].sort((a, b) =>
    {
        if(order === "asc")
        {
            return sortFcn(a[key],b[key])
        }
        else
        {
            return sortFcn(b[key],a[key])
        }
    });
    const onColumnSort = ({key, order}) =>
    {
        setSortBy({key, order});
    }

    return (
    <>
        <Grid container>
            <Grid item xs={10}>
                <h3>{props.title}</h3>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='search'
                    label='search'
                    value={searchValue}
                    onChange={onSearch}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Grid>
    <Container>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width}
            height={height}
            columns={columns}
            data={dataSorted}
            sortBy={sortBy}
            onColumnSort={onColumnSort}
          />
        )}
      </AutoResizer>
    </Container>
    </>);
}
