import React, {useState} from 'react'

import { Navigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import CameraList from './CameraList.js'

import '../../BigButton.css'

export default function CameraInventory(props)
{
    const [onRedirectToCreate, setOnRedirectToCreate] = useState(false);

    const onCreateNewCamera = (event) =>
    {
        setOnRedirectToCreate(true);

        event.preventDefault();
    };

    if(onRedirectToCreate)
    {
        return ( <Navigate to="/inventory_management/camera/create/"/>);
    }
    else
    {
        return (
            <div>
            <p>{"\n\n"}</p>
            <CameraList />
            <p>{"\n\n"}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onCreateNewCamera}>
                    Create New Camera
            </Button>
            <p>{"\n\n"}</p>
            </div>
        );
    }
}
