import './App.css';

import './bootstrap/css/bootstrap.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import LoginView from './LoginView.js'
import PageNotFoundView from './PageNotFoundView.js'
import { UserNavbar, LoggedOutNavbar } from './UserNavbar'
import HomePage from './HomePage.js'

import useToken from './api/useToken';

import InventoryManagement from './inventory_management/InventoryManagement.js'
import CabinetCreation from './inventory_management/cabinet/CabinetCreation.js'
import CabinetEdit from './inventory_management/cabinet/CabinetEdit.js'
import CameraCreation from './inventory_management/camera/CameraCreation.js'
import CameraEdit from './inventory_management/camera/CameraEdit.js'
import PowerBoardCreation from './inventory_management/power_board/PowerBoardCreation.js'
import PowerBoardEdit from './inventory_management/power_board/PowerBoardEdit.js'

import TruckLandingPage from './truck_setup/TruckLandingPage.js'
import TruckSurvey from './truck_setup/truck_survey/TruckSurvey.js'
import ConfigurationSurvey from './truck_setup/configuration_survey/ConfigurationSurvey.js'

function App() {
    const { token, setToken } = useToken();

    if(!token)
    {
        return (
            <div className="App">
                <Router>
                <LoggedOutNavbar />
                <Routes>
                <Route exact path="/" element={<LoginView setToken={setToken}/>}/>
                <Route path="/" element={<PageNotFoundView/>}/>
                </Routes>
                </Router>
            </div>
        );
    }
    else
    {
        return (
        <div className="App">
            <div>
            <Router>
            <UserNavbar setToken={setToken}/>
            <Routes>
            <Route path="*" element={<PageNotFoundView/>}/>
            <Route exact path="/" element={<HomePage/>}/>
            <Route exact path="/inventory_management/" element={<InventoryManagement/>}/>
            <Route exact path="/inventory_management/cabinet/create/" element={<CabinetCreation/>}/>
            <Route exact path="/inventory_management/cabinet/:id" element={<CabinetEdit/>}/>
            <Route exact path="/inventory_management/camera/create/" element={<CameraCreation/>}/>
            <Route exact path="/inventory_management/camera/:id" element={<CameraEdit/>}/>
            <Route exact path="/inventory_management/power_board/create/" element={<PowerBoardCreation/>}/>
            <Route exact path="/inventory_management/power_board/:id" element={<PowerBoardEdit/>}/>
            <Route exact path="/truck_setup/" element={<TruckLandingPage/>}/>
            <Route exact path="/truck_setup/create/" element={<TruckSurvey/>}/>
            <Route exact path="/truck_setup/survey/" element={<ConfigurationSurvey/>}/>
            </Routes>
            </Router>
            </div>
        </div>
        );
    }
}

export default App;
