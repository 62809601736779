import React from 'react'

import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import useSWR from 'swr'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import BigTable from '../../components/BigTable.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

function InventoryList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 300,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 300,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.cabinets.length; i++)
    {
        let cabinet = props.data.cabinets[i]

        let edit_icon = (
            <Link to={"/inventory_management/cabinet/" + cabinet.id + "/"}>
                <EditIcon/>
            </Link>
        );

        if(cabinet.truck === null)
        {
            let obj = {
                serial_no: cabinet.serial_no,
                notes: cabinet.notes,
                edit: edit_icon
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-1',
                        'order': 'desc'
                    }
                }
                title={'Unassigned Cabinets (' + data.length + ')'}
            />
        </div>
    );
}

function ProductionList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Truck (LimnTech Name)", field: "limntech_name", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Truck (Customer Name)", field: "customer_name", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Company", field: "company", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 100,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 100,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.cabinets.length; i++)
    {
        let cabinet = props.data.cabinets[i]

        if(cabinet.truck !== null)
        {
            let edit_icon = (
                <Link to={"/inventory_management/cabinet/" + cabinet.id + "/"}>
                    <EditIcon/>
                </Link>
            );

            let obj = {
                serial_no: cabinet.serial_no,
                notes: cabinet.notes,
                limntech_name: '',
                customer_name: '',
                company: '',
                edit: edit_icon
            }

            for(let j = 0; j < props.data.trucks.length; j++)
            {
                let truck = props.data.trucks[j];
                if(truck.id === cabinet.truck)
                {
                    obj["limntech_name"] = truck.limntech_name;
                    obj["customer_name"] = truck.customer_name;

                    for(let k = 0; k < props.data.companies.length; k++)
                    {
                        if(truck.company === props.data.companies[k].id)
                        {
                            obj["company"] = props.data.companies[k].name;
                            break;
                        }
                    }
                }
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-6',
                        'order': 'desc'
                    }
                }
                title={'Assigned Cabinets (' + data.length + ')'}
            />
        </div>
    );
}

export default function CabinetList(props)
{
    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: companies, error: company_error } =  useSWR(
        resourceHandler.list_companies().url, requestHandler.cacheFetcher);
    const { data: cabinets, error: cabinet_error } = useSWR(
        resourceHandler.list_cabinets().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && trucks && cabinets && companies);
    let error = !(!ca_error && !trucks_error && !cabinet_error && !company_error);

    let data = {
        company_admin: company_admin,
        trucks: trucks,
        companies: companies,
        cabinets: cabinets
    }

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        return (
            <div>
            <p>{'\n\n'}</p>
            <ProductionList data={data} />
            <p>{'\n\n'}</p>
            <InventoryList data={data} />
            </div>
        );
    }
    else
    {
        return <ResourceLoader/>
    }
}
