import React from 'react'

import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import useSWR from 'swr'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import BigTable from '../../components/BigTable.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

function InventoryList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Hardware Revision", field: "hardware_revision", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Firmware Version", field: "firmware_version", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 300,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 300,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.power_boards.length; i++)
    {
        let pb = props.data.power_boards[i]

        let edit_icon = (
            <Link to={"/inventory_management/power_board/" + pb.id + "/"}>
                <EditIcon/>
            </Link>
        );

        if(pb.is_broken === true)
        {
            continue;
        }
        if(pb.is_deleted === true)
        {
            continue;
        }
        if(pb.cabinet === null)
        {
            let obj = {
                serial_no: pb.serial_no,
                hardware_revision: pb.hardware_revision,
                firmware_version: pb.firmware_version,
                notes: pb.notes,
                edit: edit_icon
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-1',
                        'order': 'desc'
                    }
                }
                title={'Unassigned Power Boards (' + data.length + ')'}
            />
        </div>
    );
}

function ProductionList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Hardware Revision", field: "hardware_revision", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Firmware Version", field: "firmware_version", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Cabinet", field: "cabinet", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Truck (LimnTech Name)", field: "limntech_name", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Truck (Customer Name)", field: "customer_name", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Company", field: "company", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 50,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 50,
            sortable: false, searchable: false },
    ];

    let data = [];
    /*
     * This loop in not very efficient. I think it will be ok because we aren't
     * dealing with that much data. We can create lookup maps to speed this up
     * if necessary
     */
    for(let i = 0; i < props.data.power_boards.length; i++)
    {
        let pb = props.data.power_boards[i]

        if(pb.is_broken === true)
        {
            continue;
        }
        if(pb.is_deleted === true)
        {
            continue;
        }
        if(pb.cabinet !== null)
        {
            let edit_icon = (
                <Link to={"/inventory_management/power_board/" + pb.id + "/"}>
                    <EditIcon/>
                </Link>
            );

            let obj = {
                serial_no: pb.serial_no,
                hardware_revision: pb.hardware_revision,
                firmware_version: pb.firmware_version,
                notes: pb.notes,
                edit: edit_icon
            }

            let break_ = false;
            for(let j = 0; j < props.data.cabinets.length; j++)
            {
                let cabinet = props.data.cabinets[j];
                if(cabinet.id === pb.cabinet)
                {
                    obj["cabinet"] = cabinet.serial_no;
                    for(let k = 0; k < props.data.trucks.length; k++)
                    {
                        let truck = props.data.trucks[k];
                        if(truck.id === cabinet.truck)
                        {
                            obj["limntech_name"] = truck.limntech_name;
                            obj["customer_name"] = truck.customer_name;

                            for(let z = 0; z < props.data.companies.length; z++)
                            {
                                if(truck.company === props.data.companies[z].id)
                                {
                                    obj["company"] = props.data.companies[z].name;
                                    break_ = true;
                                    break;
                                }
                            }

                            if(break_)
                            {
                                break;
                            }
                        }
                    }
                    if(break_)
                    {
                        break;
                    }
                }
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-6',
                        'order': 'desc'
                    }
                }
                title={'Assigned Power Boards (' + data.length + ')'}
            />
        </div>
    );
}

function ObsoleteList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Hardware Revision", field: "hardware_revision", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Firmware Version", field: "firmware_version", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 300,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 300,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.power_boards.length; i++)
    {
        let pb = props.data.power_boards[i]

        if(pb.is_broken === false)
        {
            continue;
        }

        let edit_icon = (
            <Link to={"/inventory_management/power_board/" + pb.id + "/"}>
                <EditIcon/>
            </Link>
        );

        let obj = {
            serial_no: pb.serial_no,
            hardware_revision: pb.hardware_revision,
            firmware_version: pb.firmware_version,
            notes: pb.notes,
            edit: edit_icon
        }

        data.push(obj);
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-1',
                        'order': 'desc'
                    }
                }
                title={'Obsolete Power Boards (' + data.length + ')'}
            />
        </div>
    );
}


export default function PowerBoardList(props)
{
    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: companies, error: company_error } =  useSWR(
        resourceHandler.list_companies().url, requestHandler.cacheFetcher);
    const { data: power_boards, error: power_board_error } = useSWR(
        resourceHandler.list_power_boards().url, requestHandler.cacheFetcher);
    const { data: cabinets, error: cabinet_error } = useSWR(
        resourceHandler.list_cabinets().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && trucks && power_boards && companies && cabinets);
    let error = !(!ca_error && !trucks_error && !power_board_error && !company_error && !cabinet_error);

    let data = {
        company_admin: company_admin,
        trucks: trucks,
        companies: companies,
        power_boards: power_boards,
        cabinets: cabinets
    }

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        return (
            <div>
            <p>{'\n\n'}</p>
            <ProductionList data={data} />
            <p>{'\n\n'}</p>
            <InventoryList data={data} />
            <p>{'\n\n'}</p>
            <ObsoleteList data={data} />
            </div>
        );
    }
    else
    {
        return <ResourceLoader/>
    }
}
