import React from 'react'
import moment from 'moment'

import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import useSWR from 'swr'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import BigTable from '../../components/BigTable.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

function InventoryList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Mount Side", field: "side", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Mount Location", field: "location", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Manufacturer", field: "manufacturer", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Model", field: "model", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Product ID", field: "product_id", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "MAC Address", field: "mac_address", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Firmware Version", field: "firmware_version", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Invoice Number", field: "invoice_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Invoice Date", field: "invoice_date", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Vendor", field: "vendor", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 300,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 300,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.cameras.length; i++)
    {
        let camera = props.data.cameras[i]

        let edit_icon = (
            <Link to={"/inventory_management/camera/" + camera.id + "/"}>
                <EditIcon/>
            </Link>
        );

        if(camera.is_broken === true)
        {
            continue
        }
        if(camera.truck === null)
        {
            let date = camera.invoice_date
            if(date !== null)
            {
                date = moment(camera.invoice_date + 'Z').format(
                    'MMMM Do YYYY')
            }
            let obj = {
                side: camera.side,
                location: camera.location,
                manufacturer: camera.manufacturer,
                model: camera.model,
                product_id: camera.product_id,
                serial_no: camera.serial_no,
                mac_address: camera.mac_address,
                firmware_version:  camera.firmware_version,
                invoice_no: camera.invoice_no,
                invoice_date: date,
                vendor: camera.vendor,
                notes: camera.notes,
                edit: edit_icon
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-0',
                        'order': 'desc'
                    }
                }
                title={'Unassigned Cameras (' + data.length + ')'}
            />
        </div>
    );
}

function ProductionList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Truck (LimnTech Name)", field: "limntech_name", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Truck (Customer Name)", field: "customer_name", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Company", field: "company", width: 3000, minWidth: 200,
            sortable: true, searchable: true },
        { title: "Mount Side", field: "side", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Mount Location", field: "location", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Manufacturer", field: "manufacturer", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Model", field: "model", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Product ID", field: "product_id", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "MAC Address", field: "mac_address", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Firmware Version", field: "firmware_version", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Invoice Number", field: "invoice_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Invoice Date", field: "invoice_date", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Vendor", field: "vendor", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 100,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 100,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.cameras.length; i++)
    {
        let camera = props.data.cameras[i]

        if(camera.is_broken === true)
        {
            continue
        }
        if(camera.truck !== null)
        {
            let edit_icon = (
                <Link to={"/inventory_management/camera/" + camera.id + "/"}>
                    <EditIcon/>
                </Link>
            );

            let date = camera.invoice_date
            if(date !== null)
            {
                date = moment(camera.invoice_date + 'Z').format(
                    'MMMM Do YYYY')
            }
            let obj = {
                side: camera.side,
                location: camera.location,
                manufacturer: camera.manufacturer,
                model: camera.model,
                product_id: camera.product_id,
                serial_no: camera.serial_no,
                mac_address: camera.mac_address,
                firmware_version:  camera.firmware_version,
                invoice_no: camera.invoice_no,
                invoice_date: date,
                vendor: camera.vendor,
                notes: camera.notes,
                limntech_name: '',
                customer_name: '',
                company: '',
                edit: edit_icon
            }

            for(let j = 0; j < props.data.trucks.length; j++)
            {
                let truck = props.data.trucks[j];
                if(truck.id === camera.truck)
                {
                    obj["limntech_name"] = truck.limntech_name;
                    obj["customer_name"] = truck.customer_name;

                    for(let k = 0; k < props.data.companies.length; k++)
                    {
                        if(truck.company === props.data.companies[k].id)
                        {
                            obj["company"] = props.data.companies[k].name;
                            break;
                        }
                    }
                }
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-0',
                        'order': 'desc'
                    }
                }
                title={'Assigned Cameras (' + data.length + ')'}
            />
        </div>
    );
}

function ObsoleteList(props)
{
    let headers = [
        { title: "Serial Number", field: "serial_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Mount Side", field: "side", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Mount Location", field: "location", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Manufacturer", field: "manufacturer", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Model", field: "model", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Product ID", field: "product_id", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "MAC Address", field: "mac_address", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Firmware Version", field: "firmware_version", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Invoice Number", field: "invoice_no", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Invoice Date", field: "invoice_date", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Vendor", field: "vendor", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Change History / Comments", field: "notes", width: 3000, minWidth: 300,
            sortable: true, searchable: false },
        { title: "Edit / View", field: "edit", width: 3000, minWidth: 300,
            sortable: false, searchable: false },
    ];

    let data = [];
    for(let i = 0; i < props.data.cameras.length; i++)
    {
        let camera = props.data.cameras[i]

        let edit_icon = (
            <Link to={"/inventory_management/camera/" + camera.id + "/"}>
                <EditIcon/>
            </Link>
        );

        if(camera.is_broken === true)
        {
            let date = camera.invoice_date
            if(date !== null)
            {
                date = moment(camera.invoice_date + 'Z').format(
                    'MMMM Do YYYY')
            }
            let obj = {
                side: camera.side,
                location: camera.location,
                manufacturer: camera.manufacturer,
                model: camera.model,
                product_id: camera.product_id,
                serial_no: camera.serial_no,
                mac_address: camera.mac_address,
                firmware_version:  camera.firmware_version,
                invoice_no: camera.invoice_no,
                invoice_date: date,
                vendor: camera.vendor,
                notes: camera.notes,
                edit: edit_icon
            }

            data.push(obj);
        }
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-0',
                        'order': 'desc'
                    }
                }
                title={'Obsolete Cameras (' + data.length + ')'}
            />
        </div>
    );
}


export default function CameraList(props)
{
    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: companies, error: company_error } =  useSWR(
        resourceHandler.list_companies().url, requestHandler.cacheFetcher);
    const { data: cameras, error: camera_error } = useSWR(
        resourceHandler.list_cameras().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && trucks && cameras && companies);
    let error = !(!ca_error && !trucks_error && !camera_error && !company_error);

    let data = {
        company_admin: company_admin,
        trucks: trucks,
        companies: companies,
        cameras: cameras
    }

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        return (
            <div>
            <p>{'\n\n'}</p>
            <ProductionList data={data} />
            <p>{'\n\n'}</p>
            <InventoryList data={data} />
            <p>{'\n\n'}</p>
            <ObsoleteList data={data} />
            <p>{'\n\n'}</p>
            </div>
        );
    }
    else
    {
        return <ResourceLoader/>
    }
}
