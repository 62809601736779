import React, { useState } from 'react'

import useSWR from 'swr'
import { Table, Button, Form } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'

import ApiResponseErrorView from '../../api/ApiResponseErrorView.js'
import ResourceLoader from '../../ResourceLoader.js'
import { resourceHandler } from '../../api/resource.js'
import { requestHandler } from '../../api/request.js'

import '../../BigButton.css'

function CabinetCreationForm(props)
{
    const [serialNumber, setSerialNumber] = useState('')
    const [truckAssignment, setTruckAssignment] = useState('UNASSIGNED')
    const [powerAssignment, setPowerAssignment] = useState('UNASSIGNED')
    const [additionalComments, setAdditionalComments] = useState('')

    /*
     * State update callbacks
     */
    const onSerialChange = (event) =>
    {
        setSerialNumber(event.target.value);
    };
    const onTruckAssignment = (event) =>
    {
        setTruckAssignment(event.target.value);
    };
    const onPowerAssignment = (event) =>
    {
        setPowerAssignment(event.target.value);
    };
    const onAdditionalComments = (event) =>
    {
        setAdditionalComments(event.target.value);
    };

    const truckOptionList = () =>
    {
        let unassigned_truck_list = []

        for(let i = 0; i < props.data.trucks.length; i++)
        {
            let match = false;
            for(let j = 0; j < props.data.cabinets.length; j++)
            {
                if(props.data.cabinets[j].truck === props.data.trucks[i].id)
                {
                    match = true;
                    break;
                }
            }

            if(!match)
            {
                unassigned_truck_list.push(props.data.trucks[i]);
            }
        }

        return unassigned_truck_list.map(
            (truck, index) =>
        {
            return (
                <option value={truck.id}>
                    {truck.limntech_name}
                </option>
            );
        });
    }

    const powerOptionsList = () =>
    {
        let unassigned_power_boards = []

        for(let i = 0; i < props.data.power_boards.length; i++)
        {
            let pb = props.data.power_boards[i]

            if(pb.cabinet)
            {
                continue;
            }
            if(pb.is_broken)
            {
                continue;
            }
            if(pb.is_deleted)
            {
                continue;
            }

            unassigned_power_boards.push(pb);
        }

        return unassigned_power_boards.map(
            (pb, idx) =>
        {
            return (
                <option value={pb.id}>
                    { pb.serial_no }
                </option>
            );
        });
    }

    /*
     * Cabinet creation callbacks
     */
    const onCreateNewCabinet = (event) =>
    {
        if(!parseInt(serialNumber))
        {
            alert('Serial number needs to an integer');
            return;
        }
        for(let i = 0; i < props.data.cabinets.length; i++)
        {
            if(props.data.cabinets[i].serial_no === serialNumber)
            {
                alert('The serial number you entered is already assigned to a cabinet!');
                return;
            }
        }

        let post_obj = {
            serial_no: serialNumber,
        };

        let power_board = null;
        if(powerAssignment !== 'UNASSIGNED')
        {
            power_board = powerAssignment;
        }
        if(truckAssignment !== 'UNASSIGNED')
        {
            post_obj['truck'] = truckAssignment;
        }

        props.onSubmit(post_obj, additionalComments, power_board)
    };

    return (
        <div>
            <h1> Create Cabinet </h1>
            <Form.Group>
                <Table hover>
                    <tbody>
                        <tr>
                            <td> Serial Number </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onSerialChange}
                                    value={serialNumber}
                                    placeholder={"enter cabinet's serial number"}
                                    size={"lg"} />
                            </td>
                        </tr>
                        <tr>
                            <td> Truck Assignment </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onTruckAssignment}
                                    value={truckAssignment}
                                    size={"lg"}>
                                    <option value={'UNASSIGNED'}> Unassigned </option>
                                    { truckOptionList() }
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Power Board Assignment </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    onChange={onPowerAssignment}
                                    value={powerAssignment}
                                    size={"lg"}>
                                    <option value={'UNASSIGNED'}> Unassigned </option>
                                    { powerOptionsList() }
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td> Additional Comments </td>
                            <td>
                                <Form.Control
                                    type="input"
                                    onChange={onAdditionalComments}
                                    value={additionalComments}
                                    placeholder={"This field is optional"}
                                    size={"lg"} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form.Group>
            <p>{"\n\n"}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onCreateNewCabinet}>
                    { props.buttonText }
            </Button>
        </div>
    );
}

export default function CabinetCreation(props)
{
    const [submittingForm, setSubmittingForm] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [creationSuccessful, setCreationSuccessful] = useState(false);
    const [redirectToInventory, setRedirectToInventory] = useState(false);

    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);
    const { data: power_boards, error: power_board_error } = useSWR(
        resourceHandler.list_power_boards().url, requestHandler.cacheFetcher);
    const { data: cabinets, error: cabinet_error } = useSWR(
        resourceHandler.list_cabinets().url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && trucks && power_boards && cabinets);
    let error = !(!ca_error && !trucks_error && !power_board_error && !cabinet_error);

    const onFailure = () =>
    {
        setApiError(true);
    }
    const onRedirectInventory = () =>
    {
        setRedirectToInventory(true);
    }

    const onFormSubmitSuccess = (response) =>
    {
        setCreationSuccessful(true);
    }

    const reassignPowerBoard = (response, cabinet_id, power_board) =>
    {
        if(power_board === null)
        {
            setCreationSuccessful(true);
        }
        else
        {
            let patch_obj = {
                cabinet: cabinet_id
            };

            requestHandler.patch(
                resourceHandler.edit_power_board(power_board),
                patch_obj,
                onFormSubmitSuccess,
                onFailure);
        }
    }

    const onInitialNoteSuccess = (response, cabinet_id, additional_comment, power_board) =>
    {
        const onReassignPowerBoard = (response) =>
        {
            reassignPowerBoard(response, cabinet_id, power_board);
        }

        if(additional_comment.length === 0)
        {
            onReassignPowerBoard(null);
        }
        else
        {
            let post_obj = {
                cabinet: cabinet_id,
                comment: additional_comment,
                user: company_admin.id
            }

            requestHandler.post(
                resourceHandler.create_cabinet_note(),
                post_obj,
                onReassignPowerBoard,
                onFailure);
        }
    };
    const onCabinetCreationSuccess = (response, additional_comments, power_board) =>
    {
        let cabinet_id = response.data.id;
        let post_obj = {
            cabinet: cabinet_id,
            comment: "Initial entry manually entered",
            user: company_admin.id
        };

        const onSuccess = (response) =>
        {
            onInitialNoteSuccess(response, cabinet_id, additional_comments, power_board);
        };
        requestHandler.post(
            resourceHandler.create_cabinet_note(),
            post_obj,
            onSuccess,
            onFailure);
    }
    const onFormSubmitted = (post_obj, additional_comments, power_board) =>
    {
        const onSuccess = (response) =>
        {
            onCabinetCreationSuccess(response, additional_comments, power_board);
        };

        requestHandler.post(
            resourceHandler.create_cabinet(),
            post_obj,
            onSuccess,
            onFailure);

        setSubmittingForm(true);
    }

    if(error || apiError)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(redirectToInventory)
    {
        return ( <Navigate to="/inventory_management/"/>);
    }
    else if(creationSuccessful)
    {
        return (
            <div>
                <h1> Cabinet Creation Success </h1>
                <p> Press the button below to return to inventory </p>
                <p> You may also navigate to any other page using the banner above </p>
                <Button
                    variant="primary"
                    className="BigButton"
                    onClick={onRedirectInventory}>
                        Inventory Management
                </Button>
            </div>
        );
    }
    else if(submittingForm)
    {
        return <ResourceLoader />;
    }
    else if(loaded)
    {
        let data = {
            company_admin: company_admin,
            trucks: trucks,
            power_boards: power_boards,
            cabinets: cabinets
        };

        return (
            <CabinetCreationForm
                data={data}
                onSubmit={onFormSubmitted}
                buttonText={"Create New Cabinet"}
            />
        );
    }
    else
    {
        return <ResourceLoader />;
    }
}
