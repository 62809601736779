import React from 'react'

import useSWR from 'swr'

import ApiResponseErrorView from '../api/ApiResponseErrorView.js'
import BigTable from '../components/BigTable.js'
import ResourceLoader from '../ResourceLoader.js'
import { resourceHandler } from '../api/resource.js'
import { requestHandler } from '../api/request.js'

function TruckTable(props)
{
    let headers = [
        { title: "LimnTech Name", field: "limntech_name", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Customer Name", field: "customer_name", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
        { title: "Truck Type", field: "truck_type", width: 3000, minWidth: 300,
            sortable: true, searchable: true },
    ];

    let data = [];
    for(let i = 0; i < props.data.trucks.length; i++)
    {
        let truck = props.data.trucks[i]

        let obj = {
            limntech_name: truck.limntech_name,
            customer_name: truck.customer_name,
            truck_type: truck.truck_type,
        }

        data.push(obj);
    }

    return (
        <div>
            <BigTable
                headers={headers}
                data={data}
                defaultSort = {
                    {
                        'key': 'column-0',
                        'order': 'desc'
                    }
                }
                title={'Trucks (' + data.length + ')'}
            />
        </div>
    );
}

export default function TruckList(props)
{
    /*
     * Data Loading
     */
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);
    const { data: trucks, error: trucks_error } = useSWR(
        resourceHandler.list_trucks('').url, requestHandler.cacheFetcher);

    /*
     * Render Logic
     */
    let loaded = (company_admin && trucks);
    let error = !(!ca_error && !trucks_error);

    let data = {
        company_admin: company_admin,
        trucks: trucks,
    }

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        return (
            <div>
            <p>{'\n\n'}</p>
            <TruckTable data={data} />
            </div>
        );
    }
    else
    {
        return <ResourceLoader/>
    }
}
