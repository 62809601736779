import React, {useState} from 'react'

import { Navigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import TruckList from './TruckList.js'

import '../BigButton.css'

export default function TruckLandingPage(props)
{
    const [onRedirectToCreate, setOnRedirectToCreate] = useState(false);
    const [onRedirectToSurvey, setOnRedirectToSurvey] = useState(false);

    const onCreateNewTruck = (event) =>
    {
        setOnRedirectToCreate(true);

        event.preventDefault();
    };
    const onSurvey = (event) =>
    {
        setOnRedirectToSurvey(true);

        event.preventDefault();
    }

    if(onRedirectToCreate)
    {
        return ( <Navigate to="/truck_setup/create/"/>);
    }
    else if(onRedirectToSurvey)
    {
        return ( <Navigate to="/truck_setup/survey/"/>);
    }
    else
    {
        return (
            <div>
            <p>{"\n\n"}</p>
            <TruckList />
            <p>{"\n\n"}</p>
            <div>
            <h2 style={{color:"red"}}><b> Truck Setup Steps </b></h2>
            <p><b> 1) Create a truck using the button below. </b></p>
            <p><b> 2) Assign all of the hardware to the truck using the inventory management page. </b></p>
            <p><b> 3) Complete the configuration survey to generate the configuration file. </b></p>
            <h2 style={{color:"red"}}><b> Notes </b></h2>
            <p><b> 1) All fields supported by the inventory management will autopopulate during the survey. </b></p>
            <p><b> 2) The survey can now be completed multiple times for the same truck. </b></p>
            </div>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onCreateNewTruck}>
                    Create New Truck
            </Button>
            <p>{"\n\n"}</p>
            <Button
                variant="primary"
                className="BigButton"
                onClick={onSurvey}>
                    Configuration Survey
            </Button>
            <p>{"\n\n"}</p>
            </div>
        );
    }
}
