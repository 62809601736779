import { Table, Form }  from 'react-bootstrap'

export default function TruckInfoForm(props)
{
    const setSelectedMeasurementSystem = (event) =>
    {
        props.setSelectedMeasurementSystem(event.target.value);
    };
    const onProductTypeChange = (event) =>
    {
        props.setSelectedProductType(event.target.value);
    };
    const printSoftwareVersion = () =>
    {
        let text = ""
        for(let i = 0; i < props.data.lifemark_app_update_channel.length; i++)
        {
            let update_channel = props.data.lifemark_app_update_channel[i]
            if(update_channel.id === props.data.truck.lifemark_app_update_channel)
            {
                text = (
                    update_channel.name + '/' + update_channel.version + '@' +
                    update_channel.username + '/' + update_channel.channel)
                break;
            }
        }

        return <p><b>{text}</b></p>
    }
    const printResourceVersion = () =>
    {
        let text = ""
        for(let i = 0; i < props.data.resources_update_channel.length; i++)
        {
            let resource_channel = props.data.resources_update_channel[i];
            if(resource_channel.id === props.data.truck.configuration_update_channel)
            {
                text = resource_channel.channel_name
                break;
            }
        }
        return <p><b>{text}</b></p>
    }

    return (
        <div>
            <h3> General Truck Information </h3>
            <Form.Group className="align-items-left">
                <Table hover>
                    <tbody>
                    <tr>
                        <td>
                            Customer Name
                        </td>
                        <td>
                            <p><b>{props.data.truck.customer_name}</b></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            LimnTech Name
                        </td>
                        <td>
                            <p><b>{props.data.truck.limntech_name}</b></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Truck Type
                        </td>
                        <td>
                            <p><b>{props.data.truck.truck_type}</b></p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "25%"}}> Display Measurement Units </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedMeasurementSystem}
                                as="select"
                                onChange={setSelectedMeasurementSystem}
                                size="md">
                                <option value={'IMPERIAL'}>
                                    Imperial
                                </option>
                                <option value={'METRIC'}>
                                    Metric
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "25%"}}>
                            Software Version
                        </td>
                        <td style={{width: "75%"}}>
                            { printSoftwareVersion() }
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "25%"}}>
                            Resources Version
                        </td>
                        <td style={{width: "75%"}}>
                            { printResourceVersion() }
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "25%"}}> Product Type </td>
                        <td style={{width: "75%"}}>
                            <Form.Control
                                value={props.selectedProductType}
                                as="select"
                                name="product_selection"
                                onChange={onProductTypeChange}
                                size="md">
                                <option value={'LM100'}>
                                    LifeMark 100
                                </option>
                                <option value={'LM300'}>
                                    LifeMark 300
                                </option>
                                <option value={'LM400'}>
                                    LifeMark 400
                                </option>
                            </Form.Control>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Form.Group>
        </div>
    );
}
