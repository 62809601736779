import React from 'react';
import useSWR from 'swr'

import ApiResponseErrorView from './api/ApiResponseErrorView.js'
import ResourceLoader from './ResourceLoader.js'
import { resourceHandler } from './api/resource.js'
import { requestHandler } from './api/request.js'

export default function HomePage(props)
{
    const { data: company_admin, error: ca_error } = useSWR(
        resourceHandler.current_user().url, requestHandler.cacheFetcher);

    let loaded = (company_admin);
    let error = !(!ca_error);

    if(error)
    {
        return (
            <ApiResponseErrorView
                error={error}
            />
        );
    }
    else if(loaded)
    {
        if(company_admin.company_admin_superuser)
        {
            return (
                <div>
                <h1> Welcome { company_admin.first_name } { company_admin.last_name }! </h1>
                <p> Please select the action you would like to perform from the banner above </p>
                </div>
            );
        }
        else
        {
            return (
                <div>
                <h1> Welcome { company_admin.first_name } { company_admin.last_name }! </h1>
                <p> You do not have permission to use this website </p>
                </div>
            );
        }
    }
    else
    {
        return (
            <ResourceLoader/>
        );
    }
}
